import {
    TokenService
} from '../../Services/TokenService'
import {
    defaulturl
} from '@/Services/UrlSetting';
import {
    ApiSetUp
} from '../../Services/ApiService'
import {
    Api
} from '../../Services/ApiService'
import router from "../../router/index";
import axios from 'axios'
const api = new Api(defaulturl);
const state = {
    authenticating: false,
    accessToken: TokenService.getToken(),
    authenticationErrorCode: 0,
    authenticationError: "",
    user: {
        id:'',
        firstname:'',
        lastname:'',
        email:'',
        phone:'',
        city:'',
        address:'',
        state_id:'',
        photo:'',
        country:'',
        is_verified:0,
        job:'',
        profile_content:'',
        slug:'',


    },
    user_properties:[],
    user_saved_properties:[],
    schedules: []
}
const mutations = {
    UPDATED_USER_DATA(state, payload) {
        state.user.id = payload.id
        state.user.firstname = payload.firstname;
        state.user.lastname=payload.lastname
        state.user.email=payload.email
        state.user.phone=payload.phone
        state.user.city=payload.city
        state.user.address=payload.address
        state.user.state_id=payload.state_id
        state.user.photo = payload.photo;
        state.user.country=payload.country
        state.user.is_verified=payload.is_verified
        state.user.job=payload.job
        state.user.profile_content=payload.profile_content
        state.user.slug = payload.slug
        state.user_properties = payload.property



    },
    USER_PROPERTIES(state,payload){
        state.user_properties = payload
    },
    USER_SAVED_PROPERTIES(state,payload){
        state.user_saved_properties  = payload
    },
    loginRequest(state) {
        state.authenticating = true;
        state.authenticationError = "";
        state.authenticationErrorCode = 0;
    },


    loginSuccess(state, data) {
        state.accessToken = data.token;
        state.authenticating = false;
        state.isLoggedIn = true;

        // console.log(state.user);
    },


    loginError(state, {
        errorCode,
        errorMessage
    }) {
        state.authenticating = false;
        state.authenticationErrorCode = errorCode;
        state.authenticationError = errorMessage;
    },

    logoutSuccess(state) {
        state.accessToken = "";
        state.isLoggedIn = false;
    },
    GET_SCHEDULE(state, payload) {
        state.schedules = payload
    }
}
const actions = {
    async setCookie({commit}){
      return new Promise((resolve,reject)=>{
          return api.get_cokkie().then((res)=>{
              resolve(res);
          })
      })
    },
    async register({
                       commit
                   }, data) {
        commit("loginRequest");
        await api.register(data).then((res) => {
            let r = res.data;
            TokenService.saveToken(r.data.token)
            TokenService.saveRefreshToken(r.data.token)
            ApiSetUp.setHeader();
            commit("loginSuccess", r);
            commit("UPDATED_USER_DATA", r.data.user);
            router.replace(router.history.current.query.redirect || "/verify-email");
            return true;
        })

    },

    async login({
                    commit
                }, data) {

        commit("loginRequest");
         // await axios.get('/sanctum/csrf-cookie')
       // await axios.get('airlock/csrf-cookie').then((p)=>{
       //     console.log("stp:",p);
            await api.login(data).then((res) => {
                let r = res.data;
                TokenService.saveToken(r.data.token)
                TokenService.saveRefreshToken(r.data.token)
                ApiSetUp.setHeader();
                commit("loginSuccess", r);
                commit("UPDATED_USER_DATA", r.data.user);

                return router.push(router.history.current.query.redirect || "/home");
                // return true;
            });
        // })


    },
    updateProfileAction({commit},form){
      return new Promise((resolve,reject)=>{
          api.updateProfile(form).then((res)=>{
              let t = res.data.data;
              commit("UPDATED_USER_DATA", t);
              resolve(t);
          })
      })
    },
    async getProfileAction({commit}){
        await api.getProfileInfo().then((res)=>{
            let p = res.data.data;
            commit("UPDATED_USER_DATA", p);
        })
    },
    async getUserPropertiesAction({commit},slug){
        await api.userProperties(slug).then((res)=>{
            let p = res.data.data;
            commit("USER_PROPERTIES", p);
        })
    },
    logout({commit}) {
        TokenService.removeToken()
        TokenService.removeRefreshToken()
        ApiService.removeHeader();
        sessionStorage.clear();
        commit("logoutSuccess");
        router.push({
            name: 'login'
        });
    },
    forgotPasswordAction({commit},data){
        return new Promise((resolve,reject)=>{
            api.forgotPassword(data).then((res)=>{
                let r = res.data.data;
                resolve(r);
            });
        })
    },
    changePasswordByForgotAction({commit},data){
        return new Promise((resolve,reject)=> {
            api.changePassowrdByForgot(data).then((res) => {
                let t = res.data.data;
                resolve(t);
            })
        })
    },
    verifyusercodeAction({commit}, data) {
        return new Promise((resolve, reject) => {
            api.verifycode(data).then((res) => {
                commit("UPDATED_USER_DATA", res.data.data);
                router.push(router.history.current.query.redirect || "/home");
                resolve(res.data.data);
            })
        })
    },
    verifyUserMailAction({commit}, data) {
        return new Promise((resolve, reject) => {
            api.verifyUserEmail(data).then((res) => {
                commit("UPDATED_USER_DATA", res.data.data);
                resolve(res);
                router.push(router.history.current.query.redirect || "/home");
            })
        })
    },
    resendCodeAction({commit}){
        return new Promise((resolve, reject) => {
            api.resendCode().then((res) => {
                resolve(res);
            })
        })
    },


    logoutAction({commit}) {
        // api.logout();
        TokenService.removeToken()
        TokenService.removeUser()
        TokenService.removeRefreshToken()
        ApiSetUp.removeHeader()
        commit("logoutSuccess");
        router.push({
            name: 'login'
        });
    },
    getAllSchedule({commit}) {
        api.mySchedule().then((res) => {
            let p = res.data.data;
            commit("GET_SCHEDULE", p)
        })
    },

    getAllUserSavedPropertiesAction({commit}){
        api.all_saved_properties().then((res)=>{
            let p = res.data.data;
            commit("USER_SAVED_PROPERTIES",p);
        })
    },
    async addPropertyTofavouriteAction({commit},form){
        let r = form.user+'/'+form.property;
        console.log("save_form",form)
        await new Promise((resolve,reject)=> {
            api.add_saved_property(r).then((res) => {
                let p = res.data.data;
                resolve(p)
            })
        })
    }

}
const getters = {
    userid:state=>state.user.id,
    userIsVerified:state=>state.user.is_verified
}
const user_module = {
    state,
    mutations,
    actions,
    getters
}
export default user_module