import {
    defaulturl
} from '@/Services/UrlSetting';
import {
    PropertyApi
} from '@/Services/PropertyApi'
import axios from 'axios'
import {Api} from "../../Services/ApiService";
const api = new PropertyApi(defaulturl);
// const api = new Api();
const state = {
    all:[],
    list_type:"all",
    selected_state:"",
    home_featured_properties:[],
    home_recent_properties: [],
    home_recommended_properties:[],
    home_hot_properties:[],
    featured_properties: [],
    latest_properties: [],
    recommended_properties: [],
    hot_properties:[],
    properties_by_state:[],
    property: "",
    mylisting:[],
    property_search_result:[],
    single_property:"",
    property_offer_list:[],
    property_inspection_list:[]

};
const mutations = {
    PROPERTY_LIST_TYPE(state,payload){
        state.list_type = payload;
    },
    SELECTED_STATE(state,payload){
        state.selected_state= payload;
    },
    GET_ALL_PROPERTIES(state, payload) {
        state.all = payload
    },
    GET_HOME_FEATURED_PROPERTIES(state, payload) {
        state.home_featured_properties = payload;
    },
      GET_HOME_RECENT_PROPERTIES(state, payload) {
              state.home_recent_properties = payload
    },
    GET_HOME_RECOMMENDED_PROPERTIES(state, payload) {
              state.home_recommended_properties = payload
    },
    GET_HOME_HOT_PROPERTIES(state,payload){
       state.home_hot_properties  =payload
    },
    GET_ALL_FEATURED_PROPERTIES(state, payload) {
        state.featured_properties = payload;
    },
    GET_ALL_LATEST_PROPERTIES(state, payload) {
        state.latest_properties = payload
    },
    GET_ALL_RECOMMENDED_PROPERTIES(state, payload) {
        state.recommended_properties = payload
    },
    GET_ALL_HOT_PROPERTIES(state,payload){
        state.hot_properties = payload
    },
    GET_ALL_PROPERTIES_BY_STATE(state, payload) {
        state.properties_by_state = payload;
    },
    GET_PROPERTY_DETAILS(state, payload) {
        state.property = payload
    },
    RESET_PROPERTY_DETAILS(state, payload) {
          state.property = payload
    },
    GET_MY_LISTING_PROPERTIES(state, payload) {
        state.mylisting = payload;
    },
    PROPERTY_SEARCH_RESULT(state,payload){
        state.property_search_result =payload
    },
    SUBMIT_PROPERTY_VALUE(state,payload){
        state.single_property = payload
    },
    PROPERTY_OFFER_LIST(state,payload){
        state.property_offer_list = payload
    }  ,
    PROPERTY_INSPECTION_LIST(state,payload){
        state.property_inspection_list = payload
    }
 
};
const actions = {
    propertyListTypeAction({commit},type){
        commit("PROPERTY_LIST_TYPE",type);
    },

    allPropertiesAction({ commit }) {
        api.allProperties().then((res) => {
            let p = res.data.data;
            commit("GET_ALL_PROPERTIES", p);
        })
    },
    listPropertiesAction({commit},param){
        let type = param.type;
        let option = param.option;
        let toCommit=''

        if(option !== 'paginated'){
            switch (type) {
                case 'latest':
                    toCommit = 'GET_HOME_RECENT_PROPERTIES';
                    break;
                case 'recommended':
                    toCommit = 'GET_HOME_RECOMMENDED_PROPERTIES';
                    break;
                case 'hot':
                    toCommit='GET_HOME_HOT_PROPERTIES'
                    break;
                case 'promoted':
                    toCommit='GET_HOME_FEATURED_PROPERTIES'
                    break;
                default:
                    toCommit='GET_ALL_PROPERTIES'
                    break;

            }

        }
        else {
            switch (type) {
                case 'latest':
                    toCommit = 'GET_ALL_LATEST_PROPERTIES';
                    break;
                case 'recommended':
                    toCommit = 'GET_ALL_RECOMMENDED_PROPERTIES';
                    break;
                case 'hot':
                    toCommit='GET_ALL_HOT_PROPERTIES'
                    break;
                case 'promoted':
                    toCommit='GET_ALL_FEATURED_PROPERTIES'
                    break;
                default:
                    toCommit='GET_ALL_PROPERTIES'
                    break;

            }
        }
        api.listProperties(type,option).then((res)=>{
            let p = res.data.data;
            commit(toCommit, p);
        })
    },

    propertiesByStateAction({ commit }, state_capital) {
        console.log("from store state", state_capital)
        commit("SELECTED_STATE",state_capital);
        api.propertiesByState(state_capital).then((res) => {
            let p = res.data.data;
            commit("GET_ALL_PROPERTIES_BY_STATE", p);
        })
    },
    searchPropertiesAction({commit},searchform){
        return new Promise((resolve,reject)=>{
            api.searchProperties(searchform).then((res)=>{
                let r = res.data.data;
                commit("PROPERTY_SEARCH_RESULT",r)
                resolve(r);
            })
        })
    },

    viewPropertyDetailAction({ commit }, slug) {
        api.viewPropertyDetail(slug).then((res) => {
            let p = res.data.data;
             commit("GET_PROPERTY_DETAILS", p);
        })
    },
    resetPropertyDetailAction({commit }) {
            commit("RESET_PROPERTY_DETAILS", '');
        
    },
    propertyOfferAction({ commit }, form) {
        return new Promise((resolve, reject) => {
            return api.addPropertyOffer(form).then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            })
        })
    },
    propertyEnquiryAction({
          commit
      }, form) {
          return new Promise((resolve, reject) => {
              api.addPropertyEnquiry(form).then((res) => {
                  resolve(res);
              })
          })
    },
    propertyBookTourAction({commit}, form) {
            return new Promise((resolve, reject) => {
                return api.addPropertyBookTour(form).then((res) => {
                    resolve(res);
                })
            })
    },
    mylistingPropertyAction({ commit }) {
        api.mylistingProperties().then((res) => {
            let p = res.data.data;
            commit("GET_MY_LISTING_PROPERTIES",p)
        })
    },
    addNewOrUpdatePropertyAction({commit},data){
        let form = data.form;
        let id = data.property_slug
        return new Promise((resolve,reject)=>{
            api.addProperty(form,id).then((res)=>{
                let r = res.data.data;
                commit("SUBMIT_PROPERTY_VALUE",r)
                resolve(r);
            })
        })
    },
    addNewOrUpdatePropertyPhotoAction({commit},data){
        let form = data.form;
        let id = data.property_id
        console.log(form)
        return new Promise((resolve,reject)=>{
            axios.post(defaulturl+"agentapp/add-property-photo/"+id, form, { 
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }
               

            ).then((res)=>{
                let r=  res.data.data
                resolve(r);
                console.log('upload result',r)
            })
        })
    },
    deletePropertyImageAction({commit},id){
        return new Promise((resolve,reject)=>{
            api.deletePropertyImage(id).then((res)=>{
                resolve(res.data.data);
            })
        })
    },
    getPropertyOfferListAction({commit},slug){
        api.getPropertyListOffer(slug).then((res)=>{
            let y = res.data.data;
            commit("PROPERTY_OFFER_LIST",y)
        })
    }  ,
    getPropertyInspectionListAction({commit},slug){
        api.getPropertyListInspection(slug).then((res)=>{
            let y = res.data.data;
            commit("PROPERTY_INSPECTION_LIST",y)
        })
    }
        
};
const getters = {
    list_type: state => state.list_type,
    selected_state:state => state.selected_state,
    // next_page:state=>state.next_page
};


const property_module = {
    state,
    mutations,
    actions,
    getters
};
export default property_module