import axios from 'axios';
import {
    TokenService
} from './TokenService';
import EventBus from './Event';
import toast from './ToastService'
import {ApiSetUp} from "./ApiService";

axios.interceptors.request.use(config => {
    EventBus.$emit('loading', true);
    return config;
});

axios.interceptors.response.use(
    function (response) {
        EventBus.$emit('loading', false);
        return response;
    },
    function (err) {
        EventBus.$emit('loading', false);
        if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
            return Promise.reject(error);
        }

        if (error.response) {
            toast.error(error.response.data.data);
        }
    }
);
export class AgentApi {
    constructor(baseUrl) {
        if (TokenService.getToken()) {
            ApiSetUp.setHeader();
        }
        this.baseUrl = baseUrl;

    }
    async sendrequest(http_method, url, data) {
        return await axios.request({
            method: http_method,
            url: this.baseUrl+'agentapp/'+url,
            data: data
        });
    }
    async allAgents() {
        return this.sendrequest("get", "all-agents");
    }
    async agentPropertiesById() {
        return this.sendrequest("get", "agent-properties-name-id");
    }
    async allDevelopers() {
        return this.sendrequest("get", "all-developers");
    }
    async viewAgentProfile(slug){

        return this.sendrequest("get","agent-profile/"+slug)
    }
    async searchAgent(form){
        return this.sendrequest("post","search-agent",form);
    }
    async friendList(){
        return this.sendrequest("get","friend-list");
    }
    async compareFriendList(){
        return this.sendrequest("get","compare-friend-list");
    }async comparePropertyList(){
        return this.sendrequest("get","compare-properties-list");
    }
    async addFriend(form){
        return this.sendrequest("get","add-friend/"+form);
    }
    async addAgentSubscription(form){
        return this.sendrequest("post","add-agent-subscription",form);
    }
    async propertiesPromotionList(){
        return this.sendrequest("get","properties-promotion-list");
    }
    async propertyPromotionInsight(property_id){
        return this.sendrequest("get","property-promotion-insight/"+property_id);
    }
    async getUserInspectionList() {
        return this.sendrequest("get", 'user-inspection-list');
    }
    async getUserOfferList() {
        return this.sendrequest("get", 'user-offer-list');
    }


}

