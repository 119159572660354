<template>
    <!-- App.vue -->

    <v-app >

        <v-navigation-drawer  v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app left>
            <v-container class="mycontainer">
                <v-list dense>
                    <v-list-item >
                        <v-list-item-avatar height="60" width="60">
                            <img :src="user.photo" v-if="user.photo" />
                            <v-icon size="50" v-if="!user.photo">mdi-account-circle</v-icon>
                        </v-list-item-avatar>
                        <v-spacer></v-spacer>
                        <v-btn icon class="close_drawer_btn"  @click.stop="drawer = !drawer"><v-icon color="primary">mdi-close</v-icon></v-btn>

                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="list_title" color="accent">{{user.firstname}} {{user.lastname }}</v-list-item-title>
                            <v-list-item-subtitle class="list_subtitle"  color="accent" >
                                <span style="font-size:10px">Real Estate {{user.job}}</span>
                                <span style="font-size:10px" class="float-right">{{user.city}}, NG</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-row text-center>
                    <div class="ml-5">
                        <v-btn text class=" mytheme_btn_text_color" style="text-transform: capitalize; font-size: 11px;padding:0"  depressed small text @click="goLink('profile')">View Profile</v-btn>
<!--                        <v-btn icon  @click="goLink('edit_profile')"> <v-icon color="primary" size="20">mdi-square-edit-outline</v-icon> </v-btn>-->
                    </div>
                </v-row>
                <div class="my-divider">
                    <v-divider/>
                </div>
                <v-list dense>
                <nav-list :data=" { icon: item.icon, text:item.text,link:item.link,show:item.show }" v-for="(item,i) in Home_drawer_list" :key="i">
                </nav-list>
                </v-list>
                <v-subheader class="sub-head" style="margin: 20px 0 0px 0px;font-size: 11px;color: rgba(43, 43, 43, 0.67) !important;">Properties</v-subheader>
                <v-list dense>
                    <nav-list :data=" { icon: item.icon, text:item.text,link:item.link,show:item.show }" v-for="(item,i) in properties_drawer_list" :key="i">
                    </nav-list>
                </v-list>
                <div class="my-divider">
                    <v-divider/>
                </div>
                <v-subheader class="sub-head" style="margin: 20px 0 0px 0px;font-size: 11px;color: rgba(43, 43, 43, 0.67) !important;">Connect</v-subheader>
                <v-list dense>
                    <nav-list :data=" { icon: item.icon, text:item.text,link:item.link,show:item.show }" v-for="(item,i) in connect_drawer_list" :key="i">
                    </nav-list>
                </v-list>
                <div class="my-divider">
                    <v-divider/>
                </div>
                <nav-list :data=" { icon: item.icon, text:item.text,link:item.link,show:item.right_action }" v-for="(item,i) in setting_drawer_list" :key="i">
                </nav-list>
<!--                <v-list>-->
<!--                    <v-list-item>-->
<!--                        <v-list-item-action >-->
<!--                            <v-icon>mdi-account</v-icon>-->
<!--                        </v-list-item-action>-->
<!--                        <v-list-item-content >-->
<!--                            <v-list-item-title>Settings</v-list-item-title>-->
<!--                        </v-list-item-content>-->
<!--                        <v-list-item-action >-->
<!--                            <v-list-item-subtitle class=""  color="accent">-->
<!--                                v1-->
<!--                            </v-list-item-subtitle>-->
<!--                        </v-list-item-action>-->
<!--                    </v-list-item>-->
<!--                </v-list>-->

                    <div class="pa-2 text-center owner_div ">
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class=" first" color="secondary" style="font-size:10px">Support. Report . Terms of Use</v-list-item-title>
                                    <v-list-item-subtitle class="second"  color="accent">
                                        <span>&copy; Afreal Limited | All Right reserve</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>

            </v-container>

        </v-navigation-drawer>

        <v-app-bar height="60" :elevation="elevation" class="myappbar" :clipped-left="$vuetify.breakpoint.lgAndUp" app light v-if="showAppBar">
            <v-btn icon large v-if="!$route.meta.backRoute">
                <v-progress-circular v-if="apiloading" :size="44" color="primary" :active="apiloading"
                                     :indeterminate="apiloading" >
                    <v-avatar size="32px" item>
                        <v-img src="../../assets/logo.png" alt="Vuetify" />
                    </v-avatar>

                </v-progress-circular>
                <v-avatar v-if="!apiloading " size="44px" item  >
                    <v-img src="../../assets/logo.png" alt="Vuetify" />
                </v-avatar>
                <!-- <v-btn text icon color="" @click.stop="leftButtonAction()" v-if="$route.meta.backRoute">
              <v-icon>{{leftButtonIcon}}</v-icon>
          </v-btn> -->
            </v-btn>
            <v-btn color="accent_text" icon large v-if="$route.meta.backRoute" @click="goBack()">
                <v-icon size="20" color="#000" >mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer />
            <v-toolbar-title>
                 {{ $route.meta.title }}
            </v-toolbar-title>
            <v-spacer/>
            <v-btn class="app_button" icon height="44" width="44" @click="takeMeToSearch()" v-if="$route.name ==='home'">
                <v-icon size="25" color="primary">mdi-magnify</v-icon>
            </v-btn>
            <v-btn class="app_button" icon height="44" width="44" @click.stop="drawer =!drawer" >
                <v-icon size="25" color="primary">mdi-menu</v-icon>
            </v-btn>
        </v-app-bar>





        <!-- Sizes your content based upon application components -->
        <v-content  class="content-holder">
             <v-progress-linear :active="apiloading" :indeterminate="apiloading" absolute color="primary"> </v-progress-linear>

                <router-view ></router-view>
               
            
        </v-content>

        <bottom-nav v-if="showAppBar && showBottomBar"></bottom-nav>
<!--        <search-bar></search-bar>-->

    </v-app>
</template>
<script>
    import {
        loadingMixin
    } from '../../mixins/Loading'
    import EventBus from '@/Services/Event'
    export default {
        inject:["Bus"],
        mixins: [loadingMixin],
        data() {
            return {
                currentAppTitle:"",
                loading: true,
                router_from:'',
                hideBottomNav:true,
                dialog: false,
                bottombar_active_btn: 0,
                drawer: null,
                Home_drawer_list:[
                    {
                        icon: 'mdi-home-outline',
                        text: 'Home',
                        link: 'home',
                        show:false
                    },
                ],
                properties_drawer_list:[
                    {
                    icon: 'mdi-view-grid-plus-outline',
                    text: 'Add Property',
                    link: 'add_property',
                    show:false
                    },
                    {
                        icon: 'mdi-format-list-bulleted',
                        text: 'My Listing',
                        link: 'mylisting',
                        show:false
                    },

                    {
                        icon: 'mdi-bookmark-outline',
                        text: 'Saved Properties',
                        link: 'mycollection',
                        show:false
                    },
                    {
                        icon: 'mdi-signal-cellular-outline',
                        text: 'Promotions',
                        link: 'promotion_list',
                        show:false
                    },],
                connect_drawer_list: [
                    // {
                    //     icon: 'mdi-chat-outline',
                    //     text: 'Messages',
                    //     link: 'userlist',
                    // },

                    {
                        icon: 'mdi-link-variant',
                        text: 'My Connections',
                        link: 'connect_friend',
                    },
                    {
                        icon: 'mdi-account-group-outline',
                        text: 'All Agents & Developers',
                        link: 'connect_list',
                    },
                    {
                        icon: 'mdi-account-plus-outline',
                        text: 'Invite a Colleague',
                        link: 'setting',
                    },


                ],
                setting_drawer_list:[
                    {
                        icon: 'mdi-cog-outline',
                        text: 'Setting',
                        link: 'profile',
                        right_action:true
                    },
                    {
                        icon: 'mdi-logout-variant',
                        text: 'Logout',
                        link: 'logout',
                        right_action:false
                    },
                ]
            }
        },
        computed: {

            showAppBar(){
                return this.$route.name !== 'propertydetail';

            },
            showBottomBar(){
                    let status = true
                    switch (this.$route.name) {
                        case 'edit_profile':
                            status = false;
                            break;
                        case 'promotion_create':
                            status = false;
                            break;
                        case 'add_property':
                            status = false;
                            break;
                        default:
                            status = true;
                            break;
                    }


                    return status;

                // return this.$route.name !== 'promotion_create';
            },
            elevation(){
                let return_value = 1;
                    switch (this.$route.name) {
                        case 'search':
                            return_value=0
                            break
                        case 'connect_list':
                            return_value=0
                            break
                        case 'userlist':
                            return_value=0
                            break
                        case 'property_list':
                            return_value=0
                            break
                        case 'promotion':
                            return_value=1
                            break
                        case 'connect_friend':
                            return_value=0
                            break
                        case 'add_property':
                            return_value=0
                            break
                        case 'property_offer_list':
                            return_value=0
                            break;
                        case 'property_inspection_list':
                                return_value = 0
                            break;
                        default:
                            return_value=1
                            break
                    }
                    return return_value;
                },
            // currentAppTitle(){
            //     return this.$route.meta.title;
            // },
            leftButtonIcon() {
                if (this.$route.meta.backRoute) {
                    this.hideBottomNav=false
                    return 'mdi-chevron-left'
                }
                this.hideBottomNav =true
                return 'mdi-menu'
            },
            user(){
                return this.$store.state.user_module.user;
            }

        },
        methods: {
            goLink(link) {
                if (link === 'logout') {
                     this.$store.dispatch("logoutAction");
                } else {
                   return this.$router.push({name:link})
                }
            },
            goBack(){
                // let noBackRoute = ['search'];
                // const last_visited_route = localStorage.getItem("last_visited_route")
                // console.log("prev",last_visited_route)
                // if(noBackRoute.includes(last_visited_route)){
                //
                //     this.$router.push('/home')
                // }else{
                //     this.$router.go(-1);
                // }
                this.$router.go(-1);
            },
            toggleDrawer(){
                return this.drawer !== this.drawer;
            },
            showMenu(){
                this.toggleDrawer();
            },

            leftButtonAction() {
                if (this.$route.meta.backRoute) {
                    this.$router.go(-1);
                    this.hideBottomNav=true
                } else {
                     console.log(this.drawer)
                    this.toggleDrawer();
                    this.hideBottomNav=false
                }
            },
            takeMeToSearch(){
                this.Bus.$emit("search-type",false)
                this.$router.push({name:'search'})
            },
            textSearch(){
                let s = {
                    location:'Ikeja',
                    property_type :"Duplex",
                    bedroom:"2",
                    bathroom:"4",
                    price:[2000000,45000000]
                }
                this.Bus.$emit("search-property",s);
                this.$router.push({name:'search'})
            },
            fetchAllFirstApiMode() {
                this.$store.dispatch("listPropertiesAction",{type:'promoted',option:'promoted'});
                this.$store.dispatch("listPropertiesAction",{type:'latest',option:'latest'});
                this.$store.dispatch("listPropertiesAction",{type:'recommended',option:'recommended'});
                this.$store.dispatch("listPropertiesAction",{type:'hot',option:'hot'});
                this.$store.dispatch("getAllStateAction");
                this.$store.dispatch("getAllPropertyClassAction");
                this.$store.dispatch("allCompareUserList");
                this.$store.dispatch("allComparePropertyList");
            }
        },
        created() {
            this.fetchAllFirstApiMode();
        },
    }
</script>
<style>

    .close_drawer_btn{
        /* top:-90px; */
        border-radius:50%;
        /*border:1px solid #000;*/
        left:5px;
        /*margin-top:10px;*/
    }
    .content-holder {
        background-color: #fff;
        
    }
    .sidebar-avatar-title-div{
        margin:0 15px;
        padding:0;

    }

    .app_button{
        /*width: 37.69px;*/
        /*height: 36.57px;*/
        margin:0 10px;
        background-color: rgba(0, 117, 242, 0.05);
        color:#0077F3;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
        border-radius: 100px;
    }
    .v-content__wrap{
        padding-bottom: 40px;
    }

    .user_mail{
        color:#fff !important;
    }

/*    Theme Style*/
    .v-application .elevation-1 {
        -webkit-box-shadow:  0 12px 14px rgba(0, 0, 0, 0.02) !important;
        box-shadow:  0 12px 14px rgba(0, 0, 0, 0.02)!important;
        /*box-shadow:  0 12px 23px rgba(0, 0, 0, 0.05)!important;*/
    }
    .myappbar{
        padding:0 5px;
        /*box-shadow: 0px 22px 23px rgba(0, 0, 0, 0.05);*/
        /*-webkit-box-shadow:  0 12px 14px rgba(0, 0, 0, 0.02) !important;*/
        /*box-shadow:  0 12px 14px rgba(0, 0, 0, 0.02)!important;*/
    }
    .v-list {
        padding:0 0 !important;
    }
    .list_title{
        font-size: 18px !important;
        line-height: 18px !important;
    }
    .list_subtitle{
        font-size: 12px !important;
    }
    .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: 10px !important;
    }
    .v-list-item {
        padding:0 10px !important;
    }
    .theme--light.v-btn {
         color: #0077F3 !important;
    }
    .mytheme_title_color{
        color:#2B2B2B;
    }
    .mytheme_subtitle_color{
        color: rgba(0, 0, 0, 0.47);
    }
    .mytheme_btn_background{
        /*background-color: rgba(0, 117, 242, 0.05);*/
        background-color: rgba(0, 119, 243, 0.05);
    }
    .mytheme_btn_text_color{
        color:#0077F3;
        /*font-style: bold;*/
    }
    .owner_div{
        position: relative;
        width: 224px;
        height: 33px;
        /*left: 31px;*/
        margin:5px auto;
        bottom: 0;
        /*top: 764px;*/
    }
    .owner_div .first{
        color: #379392;
        font-size: 10px;
        line-height: 13px;
    }
    .owner_div .second{
        font-size: 10px;
        line-height: 13px;
        color:rgba(0, 0, 0, 0.47);
    }

  
.sub-head {
    /* margin: 20px 0 0px 0px;font-size: 11px;color: rgba(43, 43, 43, 0.67); */
}

</style>