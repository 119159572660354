import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0077F3',
                secondary: '#379392',
                accent: 'rgba(0, 117, 242, 0.05)',
                accent_text:'rgba(0, 0, 0, 0.47)',
                error: '#FF5252',
                info: '#C4C4C4',
                success: '#C4C4C4',
                warning: '#FFC107',
                link:'#FF5252'
            },
            dark: {
                primary: '#4CAF50',
                secondary: '#2196F3',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                link: '#FF5252'
            },
        }
    },
    // icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    // },
});
