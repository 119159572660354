import axios from 'axios';
import {
    TokenService
} from './TokenService';
import EventBus from './Event';
import toast from './ToastService'
import {ApiSetUp} from "./ApiService";

axios.interceptors.request.use(config => {
    EventBus.$emit('loading', true);
    return config;
});

axios.interceptors.response.use(
    function (response) {
        EventBus.$emit('loading', false);
        return response;
    },
    function (err) {
        EventBus.$emit('loading', false);
        if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
            return Promise.reject(error);
        }

        if (error.response) {
            // toast.error(error.response.data.data);
            let r = error.response.data.data;
       
            toast.error(JSON.stringify(r));
        }
    }
);
export class PropertyApi {
    constructor(baseUrl) {
        if (TokenService.getToken()) {
            ApiSetUp.setHeader();
        }
        this.baseUrl = baseUrl;
        // if(TokenService.getToken()){
        //     ApiSetUp.setHeader();
        // }
        //  axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`

    }
    async sendrequest(http_method, url, data) {
        return await axios.request({
            method: http_method,
            url: this.baseUrl+'agentapp/'+url,
            data: data
        });
    }

    // property apis
    async allProperties() {
        return await this.sendrequest("get", "all-properties");
    }
    async listProperties(type,option) {
        let params = type+"/"+option;
        return await this.sendrequest("get", "list-properties/"+params);
    }
    async featuredProperties() {
        return await this.sendrequest("get", "featured-properties");
    }
    async paginatedFeaturedProperties() {
        return await this.sendrequest("get", "all-featured-properties");
    }
    async recentProperties() {
        return await this.sendrequest("get", "recent-properties");
    }
    async paginatedRecentProperties() {
        return await this.sendrequest("get", "all-recent-properties");
    }
    async nextPaginatedRecentProperties(next) {
        return await this.sendrequest("get", "all-recent-properties?page=" + next);
    }
    async recommendedProperties() {
        return await this.sendrequest("get", "recommended-properties");
    }
    async paginatedRecommendedProperties() {
        return await this.sendrequest("get", "all-recommended-properties");
    }
    async propertiesByState(state) {
        console.log("form api state,",state)
        return await this.sendrequest("get", "properties-by-state/" + state);
    }

    async addProperty(form,propertyid) {
        return await this.sendrequest("post", "add-new-property/"+propertyid, form);
    }
    async addPropertyImage(form, propertyid) {
        // ApiSetUp.addFileUploadHeader();
        return await this.sendrequest("post", "add-property-photo/"+propertyid, form);
    }
    async deletePropertyImage(propertyid) {
        // ApiSetUp.addFileUploadHeader();
        return await this.sendrequest("get", "delete-property-photo/"+propertyid);
    }
    async addPropertyType(form, propertyid) {
        return await this.sendrequest("post", "add/property-type/" + propertyid, form);
    }
    async addPropertyAmenities(form, propertyid) {
        return await this.sendrequest("post", "add/property-amenities/" + propertyid, form);
    }
    async loadMore(path) {
        return await this.sendrequest("get", path);
    }
    async searchProperties(form){
        return await this.sendrequest("post","search-properties",form)
    }
    async viewPropertyDetail(slug) {
        console.log(this.baseUrl + "property-details/" + slug)
        return await this.sendrequest("get", "property-details/" + slug);
    }
    async addPropertyOffer(form) {
        return this.sendrequest("post", "add-property-offer", form);
    }
    async addPropertyEnquiry(form) {
        return this.sendrequest("post", "add-property-enquiry", form);
    }
    async addPropertyBookTour(form) {
        return this.sendrequest("post", "add-property-booktour", form);
    }
    async mylistingProperties() {
        return this.sendrequest("get", "my-properties-listing");
    }
    async getPropertyListInspection(slug) {
        return this.sendrequest("get", 'my-property-inspection-list/'+slug);
    }

    async getPropertyListOffer(slug) {
        return this.sendrequest("get", "my-property-offer-list/"+slug);
    }


}

