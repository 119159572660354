<template>
  <div>
    <v-container>
      <v-row align-center justify="center" fill-height>
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-0 mt-5" flat>
            <v-card-title class="font-weight-black">Welcome back</v-card-title>
            <v-card-subtitle>Login to continue</v-card-subtitle>
            <v-spacer />
            <v-card-text>
              <v-form ref="loginform">
                <v-text-field
                  outlined
                  label="Email"
                  type="text"
                  ref="email"
                  v-model="login_form.email"
                  name="email"
                  :rules="[rules.required,rules.email]"
                />

                <v-text-field
                  outlined
                  ref="password"
                  label="Password"
                  v-model="login_form.password"
                  name="password"
                  :append-icon="show_pass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="() => (show_pass = !show_pass)"
                  :type="show_pass ? 'text' : 'password'"
                  :rules="[rules.required]"
                />
              </v-form>
              <div class="forgot-div">
                <router-link :to="{name:'forgot'}" class="a-link" color="link">Forgot Password ?</router-link>
              </div>
              <div style="margin-top:20px">
                <v-btn style="color:#fff !important;" height="48"
                  type="submit"
                  :loading="apiloading"
                  :disabled="apiloading"
                  block
                  color="secondary"
                  @click.prevent="login()"
                >Login</v-btn>
              </div>
             
            </v-card-text>
             <v-card-actions class="justify-center">
                 <p>Don't have Account?<router-link :to="{name:'register'}"><span  class="a-link"> Sign up</span></router-link></p>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { loadingMixin } from "../../mixins/Loading";
import axios from "axios";
// import { formMixin } from "../../mixins/form";
export default {
  mixins: [loadingMixin],
  data() {
    return {
      show_pass: false,
      login_form: {
        email: "",
        password: "",
        usertype:"Agent"
      },
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail address.";
        }
      },
      theme:false,
      
    };
  },
  computed: {
    showtheme(){
      if(this.theme){
        return $vuetify.theme.themes.light
      }
      return $vuetify.theme.themes.dark
    }
  },
  methods: {
    toggle(){
      return this.theme != this.theme;
    },
    goForgot() {
      this.$router.push({
        name: "forgot"
      });
    },
    login() {
      // if (this.validateForm(this.login_form)) {
      if (this.$refs.loginform.validate()) {
          // Login...
          this.$store.dispatch("login",this.login_form);


      } else {
        return;
      }
    }
  }
};
</script>
<style scoped>
  .v-btn__content {
    color:#fff !important;
    text-transform: capitalize !important;
  }
  .a-link{
    color:#379392;
    text-decoration: none;
  }
</style>