<template>
    <div>
        <v-card :width="width" height="226px" elevation="0" @click="viewDetail(property)">
            <div :style="{ backgroundImage: 'url('+property.property_cover_image + ')' }" class="featured_property_img">
                <div class="property_img_top_btn">
                    <v-chip class="ma-2 promoted" label color="secondary" v-if="showSaveIcon">Promoted</v-chip>
                    <v-spacer />
                    <v-btn icon class="save_btn" :class="[showSaveIcon?'':'add_background']" type="button"  @click.prevent="isSavedProperty?showSaveIcon?'':'':showSaveIcon?savedProperty(property.id):editProperty(property.slug)" :loading="apiloading" :disabled="apiloading">
                        <v-icon color="white" size="30"

                        >{{showSaveIcon?isSavedProperty?'mdi-bookmark':'mdi-bookmark-outline':'mdi-home-edit-outline'}}</v-icon>
                    </v-btn>
                </div>
            </div>
            <div>
                <v-card-title style="padding:5px">
                    <span class="property-price">{{property.property_price| currency}}</span>
                    <v-spacer></v-spacer>
                    <span class="subtitle">
                                {{property.property_bedrooms}}bd.
                                {{property.property_bathrooms}}bth. {{property.property_size}}sm
                    </span>
                </v-card-title>
                <v-card-subtitle class="property-name">
                    <span class="subtitle break-word">{{property.property_name  | shortNameBy45}}</span>
                    <v-btn class="status-label" style="margin-top:-5px" depressed small text >{{property.property_status}}</v-btn>

                </v-card-subtitle>
            </div>
        </v-card>
    </div>
</template>

<script>
    import {propertyMixin} from "../../mixins/Property";
    import toast from "../../Services/ToastService";
    import {loadingMixin} from "../../mixins/Loading";

    export default {
        name: "BigPropertyCard",
        props:{
            property:{type:Object,required:true},
            showSaveIcon:{type:Boolean,default:true},
            width:{type:String,default:"298"}
        },
        mixins:[propertyMixin,loadingMixin],
        computed: {
           isSavedProperty(){
               return this.checkIfPropertyIsSaved(this.property.id)
           }
        },
        methods: {
            savedProperty(propertyid){
                let user_id = this.$store.getters.userid
                let form = {user:user_id,property:propertyid}
                this.$store.dispatch("addPropertyTofavouriteAction",form).then((res)=>{
                    toast.success("Property added to your collection")
                })
            },
            editProperty(slug){
                console.log('slug',slug);
                this.$router.push({name:'add_property',params:{type:slug}});
            }
        },
    }
</script>

<style scoped>
    .featured_property_img {
        background-size: cover;
        width: 100%;
        height: 140px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
    }
    .property_img_top_btn {
        /* margin:5px 5px; */
        display: flex;
        z-index: 10;
    }
    .save_btn{
        z-index:5000;
        font-weight: 500;
        position: relative;
        width:40px;
        height: 40px;
         
    }
    .add_background{
background-color: rgba(33, 33, 33,0.43);
        border-color: rgba(33, 33, 33,0.43);
    }
    .property_nav{
        font-weight: 500;
        opacity: 0.46;
        background-color: rgba(33, 33, 33,0.43);
        border-color: rgba(33, 33, 33,0.43);
    }
    .property_img_top_btn .ma-2 {
        color: white !important;
        height: 25px !important;
        font-weight: bold;
    }
    .property-price{
        font-family: Circular Std;
        font-style: normal;
        font-weight: 900;
        font-size: 17px;
        line-height: 22px;
        /* identical to box height */

        letter-spacing: 0.06em;

        color: #000000;
    }
    .property-name{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top:1px;
        padding:4px;
    }
    .subtitle {
        color: rgba(0, 0, 0, 0.47);
        font-size: 11px;
        /*white-space: initial;*/
        line-height: 12px;

    }
    .status-label{
        background-color: rgba(0, 119, 243, 0.05);
        color:#0077F3;
        font-size:10px;
        min-width:120px;
        overflow-wrap: break-word;
        min-height: 20px;
        white-space: initial;
        /*font-wi*/
    }
</style>