export const propertyMixin = {
      inject: ["Bus"],
    methods: {
        viewDetail(property) {
            this.$router.push({
                name: 'propertydetail',
                params: {
                    'slug': property.slug
                }
            });
        },

        showStateProperties(state) {
        //    console.log(state)
            this.Bus.$emit('selected_capital', state);
                   this.$router.push({
                       name: 'propertylist',
                       params: {
                           'type': 'capital'
                       }
                   });
        },

        
    }
}