import axios from 'axios';
import {
    TokenService
} from './TokenService';
import EventBus from './Event';
import toast from './ToastService'

axios.interceptors.request.use(config => {
    EventBus.$emit('loading', true);
    return config;
});

axios.interceptors.response.use(
    function (response) {
        EventBus.$emit('loading', false);
        return response;
    },
    function (err) {
        EventBus.$emit('loading', false);
        if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
            return Promise.reject(error);
        }

        if (error.response) {
            toast.error(error.response.data.data);
        }
    }
);
export class GeneralApi {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        //  axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
     
    }
    async sendrequest(http_method, url, data) {
        return await axios.request({
            method: http_method,
            url: this.baseUrl+url,
            data: data
        });
    }
  async states() {
      return await this.sendrequest("get", "general/all-states");
  }

  async all_local_govt(stateid) {
      return await this.sendrequest("get", "general/all-lgas/" + stateid);
  }
  async usertypes() {
      return await this.sendrequest("get", "general/all-usertypes");
  }
  async property_class() {
        return await this.sendrequest("get", "general/all-property-class");
  }
  async propertyTypes(class_id) {
        return await this.sendrequest("get", "general/all-properties-types/"+class_id);
  }
    async getAmenities() {
        return await this.sendrequest("get", "general/amenity-list");
    }
    async getPropertyStatus() {
        return await this.sendrequest("get", "general/property-status");
    }
    async getTitleDoc() {
        return await this.sendrequest("get", "general/title-docs");
    }
    async getPaymentOptions() {
        return await this.sendrequest("get", "general/payment-options");
    }
    async getFinanceOption() {
        return await this.sendrequest("get", "general/finance-option");
    }


  async updateRole(data, slug) {
      return await this.sendrequest("post", "admin/update-role/" + slug, data);
  }
  async deleteRole(slug) {
      return await this.sendrequest("get", "admin/delete-role/" + slug);
  }
    async promotionPlacement() {
        return await this.sendrequest("get", "general/promotion-placement");
    }
    async promotionDuration() {
        return await this.sendrequest("get", "general/promotion-duration");
    }
    async promotionPlan() {
        return await this.sendrequest("get", "general/promotion-plan");
    }
    async addpayment(form) {
        return await this.sendrequest("post", "general/add-user-payment",form);
    }
    async userWallet() {
        return await this.sendrequest("get", "general/user-wallet");
    }
}