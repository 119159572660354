<template>
    <div>
            <v-card  elevation="0" class="section_height" v-if="!apiloading">
                <v-card-title  class="top_title">
<!--                    <v-subheader class="title">{{title}} <span class="home-subtitle">{{subtitle}}</span></v-subheader>-->
                    <span class="title" style="width:85%">{{title}} <span class="home-subtitle">{{subtitle}}</span></span>

                    <v-spacer></v-spacer>
                    <span @click="showAll()" class="see_all_link">See all</span>
                </v-card-title>
                <section class="property_scroller">
                        <v-col style="padding:8px;flex-grow:0 !important;"
                            v-for="(property,index) in properties"
                            :key="index"
                            >
                            <v-card width="145px" height="180px" elevation="0" @click="viewDetail(property)">
                                <!-- <v-card-title v-text="card.title"></v-card-title> -->
                                <div :style="{ backgroundImage: 'url(' + property.property_cover_image+ ')' }" class="property_img">
                                    <div class="property_img_top_btn">
                                        <v-chip class="ma-2 price-status" color="secondary" label >{{property.property_price | currency}}</v-chip>
                                    </div>
                                </div>
                                    <v-card-title style="padding:5px;margin-top:2px">
                                        <span class="pro_title break-word">{{property.property_name | shortNameBy25}}</span>
                                    </v-card-title>
                                    <v-card-subtitle style="padding:5px;">
                                        <span class="pro_subtitle">{{property.property_city}}, {{property.property_state}}</span>
                                    </v-card-subtitle>

                            </v-card>
                        </v-col>
                    </section>
                     
            </v-card>
            <div  style="display: flex;justify-content: flex-start; width:100%"  v-if="apiloading">
            <skeleton-loader type="card" :width=120 :height=150  v-for="i in 5" :key="i" style="margin:0 10px" ></skeleton-loader>
            </div>
    </div>
</template>
<script>
import {loadingMixin} from "../../mixins/Loading";
import EventBus from '../../Services/Event'
import {propertyMixin} from "../../mixins/Property";
export default {
    inject:["Bus"],
    mixins:[propertyMixin,loadingMixin],
    props:['properties','title','subtitle','see_all'],
    // inject:["imgUrl"],
    
    methods: {
        showAll(){
            this.$store.dispatch("propertyListTypeAction",this.see_all);
            return this.$router.push({name:'property_list'});
        }
    },

}
</script>
<style scoped>

    .home-subtitle{
        font-family: Circular Std;
        font-size:10px;
        line-height: 12px;
        color:#9B9B9B;
        letter-spacing: 0.002px;
        font-style: normal;

    }

    .section_height{
        height: 250px;
    }
    
    .see_all_link{
        width:10%;
        text-decoration: none;
        font-size:10px;
        cursor: pointer;
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        line-height: 12px;
        text-align: right;
        letter-spacing: 0.002px;

        color: #379392;
    }
    .property_scroller{
        background-color: #fff;
        min-width: 100%;
        height: 200px;
        display: flex;
        overflow-x: auto;
        overflow-y:hidden;
    }
    .property_scroller::-webkit-scrollbar {
    display: none;
    }
    .property_img{
    background-size: cover;
    width:100%;
    height: 120px;
    background-position:center; 
    background-repeat: no-repeat;
    border-radius: 7px;
        /*border:1px solid #fefefe;*/
    }
    .property_img_top_btn{
        /* margin:5px 5px; */
        display: flex;
        z-index:1;
    }
    .property_img_top_btn .ma-2{

        /*background-color:blue;*/
        /*font-weight:bold;*/
    }
   .pro_title{
        font-family: Circular Std;
        font-style: normal;
        /*font-weight: bold;*/
       color: #4A4A4A;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 6px;
       /*white-space:pre-wrap;*/

    }
    .price-status{
        font-family: Circular Std;
        color:white !important;
        height: 20px !important;
        width:100%;
        font-size:13px;
        line-height: 14px;
        background-color: #379392;
    }

    .pro_subtitle{
            padding-top:5px;
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.47);
    }
</style>