<template>
    <div>

            <v-stepper v-model="step" style="margin-top:-6px">
                <search-area  :elevate=0 height="0">
                    <template v-slot:item>
                        <v-stepper-header>
                            <v-stepper-step :complete="step > 1" step="1" :editable="step ===1">Description</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 2" :editable="step ===2" step="2">Location</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 3" :editable="step ===3" step="3">Location</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 4" :editable="step ===4" step="4">Location</v-stepper-step>
                        </v-stepper-header>
                    </template>
                </search-area>
                <v-stepper-items >
                    <v-form ref="step_one_form" lazy-validation>
                    <v-stepper-content step="1"  >

                            <label class="form-label">Single or Estate</label>
                            <div class="radios">
                                <input type="radio" name="estate" @change="isEstateValue"  v-model="main_form.is_estate"  value="0" id="a" :rules="[rules.required]"  />
                                <label class="radio text-center" for="a" style="width: 120px;height: 50px">
                                    <span >Standalone</span>
                                </label>
                                <input type="radio" name="estate"  @change="isEstateValue" v-model="main_form.is_estate"  value="1" id="b" :rules="[rules.required]" />
                                <label class="radio text-center" for="b" style="width: 120px;height: 50px">
                                    <span >Estate</span>
                                </label>
                            </div>

                            <div class="form-group" style="margin-top:10px">
                                <label class="form-label">Property Type</label>
                                <span id="class-id" style="padding:0">
                                <v-overflow-btn clearable  outlined open-on-clear
                                                v-model="main_form.property_class_id"
                                                :items="property_class"
                                                item-text="name"
                                                item-value="id"
                                                label="select"
                                                target="#class"
                                                :rules="[rules.required]"
                                ></v-overflow-btn>
                            </span>
                            </div>
                              <div class="form-group" style="margin-top:-10px" v-if="show_plot_price">
                                     <v-text-field
                                                label="Price" v-model="main_form.price" clearable
                                                name="price"
                                                single-line
                                                outlined
                                                  :rules="[rules.phone]"
                                        >
                                        </v-text-field>
                            </div>

                        <v-expansion-panels multiple v-if="show_add_unit_type"    style="margin-top: -15px" >
                            <v-expansion-panel :class="expansion_panel_error?'invalid-panel':''"
                                    v-for="(unit, index) in unit_type" :key="index" 
                              >
                                <v-expansion-panel-header> Add Unit Type</v-expansion-panel-header>
                                <v-expansion-panel-content  >
                                    <div class="form-group">
                                        <label class="form-label">Property Type</label>
                                        <span id="#unit_type_id"  style="padding:0">
                                                    <v-overflow-btn v-model="unit.hometype"
                                                                    clearable outlined open-on-clear
                                                                    :items="property_type"
                                                                    item-value="name"
                                                                    item-text="name"
                                                                    label="select"
                                                                    name="home_type"
                                                                    :loading="apiloading"
                                                                    target="unit_type_id-type-id"
                                                                    :rules="[rules.required]"
                                                    ></v-overflow-btn>
                                                </span>
                                    </div>
                                    <div class="form-group" style="margin-top:-25px">
                                        <label class="form-label">Bedrooms</label>
                                        <v-overflow-btn v-model="unit.bedrooms"
                                                        clearable outlined open-on-clear
                                                        :items="[0,1,2,3,4,5,'6+']"
                                                        label="select"
                                                        :rules="[rules.required]"
                                        ></v-overflow-btn>

                                    </div>
                                    <div class="form-group" style="margin-top:-25px">
                                        <label class="form-label">Bathrooms</label>
                                        <v-overflow-btn v-model="unit.bathrooms"
                                                        clearable outlined open-on-clear
                                                        :items="[0,1,2,3,4,5,'6+']"
                                                        label="select"
                                                        :rules="[rules.required]"
                                        ></v-overflow-btn>
                                    </div>
                                    <div class="form-group" style="margin-top:-25px">
                                        <label class="form-label">Garage</label>
                                        <v-overflow-btn v-model="unit.garages"
                                                        clearable outlined open-on-clear
                                                        :items="[0,1,2,3,4,5,'6+']"
                                                        label="select"
                                                        :rules="[rules.required]"
                                        ></v-overflow-btn>
                                    </div>
                                    <div class="form-group" style="margin-top:-25px">
                                        <label class="form-label">Price</label>
                                        <v-text-field
                                                label="Price" v-model="unit.price" clearable
                                                name="price"
                                                single-line
                                                outlined
                                                :rules="[rules.required]"
                                        >
                                        </v-text-field>
                                    </div>
                                    <!--                                            <div class="form-group" style="margin-top:-25px">-->
                                    <!--                                                <label class="form-label">Sales Status</label>-->
                                    <!--                                                <v-select v-model="unit_type_form.is_sold" clearable-->
                                    <!--                                                          :items="sales_status"-->
                                    <!--                                                          item-text="text"-->
                                    <!--                                                          item-value="value"-->
                                    <!--                                                          label="Sale Status"-->
                                    <!--                                                          outlined-->
                                    <!--                                                          :rules="[rules.required]"-->
                                    <!--                                                ></v-select>-->
                                    <!--                                            </div>-->
                                    <div class="form-group" style="margin-top:-25px">
                                        <label class="form-label">Unit Status</label>
                                        <v-overflow-btn v-model="unit.status"
                                                        name="status"
                                                        clearable outlined open-on-clear
                                                        :items="property_status"
                                                        item-value="name"
                                                        item-text="name"
                                                        label="select"
                                                        :loading="apiloading"
                                                        target="unit_type_id-type-id"
                                                        :rules="[rules.required]"
                                        ></v-overflow-btn>
                                    </div>
                                    <div style="width: 100%;display: flex;">
                                        <v-btn color="blue darken-1" v-if="index !==0"  text @click="removeLine(index)">Remove</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn width="150" ref="add_btn" 
                                               color="secondary"   @click.prevent="addLine()" class="inspection_btn" style=" border-radius: 5px;color: #fff !important;">Add</v-btn>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
        </v-stepper-content>
                    <v-stepper-content step="2" >
                        <v-text-field ref="name" outlined label="Property Name" placeholder="eg Marian Court" type="text" :rules="[rules.required]" v-model="main_form.property_name"></v-text-field>
                        <v-text-field ref="name" outlined label="Property Size in SQM" placeholder="eg 258 " type="text" :rules="[rules.required]" v-model="main_form.property_size"></v-text-field>
                        <v-autocomplete
                                v-model="main_form.state_id"
                                :items="states"
                                :search-input.sync="state_search"
                                item-value="id"
                                item-text="name"
                                cache-items
                                :rules="[rules.required]"
                                placeholder="eg Lagos"
                                label="State"
                                clearable
                                outlined
                        ></v-autocomplete>
                        <v-autocomplete
                                v-model="main_form.lga_id"
                                :items="lgas"
                                :search-input.sync="lga_search"
                                item-value="id"
                                item-text="name"
                                cache-items
                                :rules="[rules.required]"
                                placeholder="eg Surulere"
                                label="Locality"
                                clearable
                                outlined
                        ></v-autocomplete>
                        <v-text-field ref="city" outlined label="City" placeholder="Ojuelegba" type="text" :rules="[rules.required]" v-model="main_form.property_city"></v-text-field>
                        <v-textarea :rules="[rules.required]" v-model="main_form.property_full_address" auto-grow height="60" placeholder="20, okoro street" outlined label="Address" clearable
                        ></v-textarea>
                    </v-stepper-content>
                    <v-stepper-content step="3" >
                        <label class="form-label">Property Description</label>
                        <my-vue-editor :rules="[rules.required]" v-model="main_form.property_description"></my-vue-editor>
                        <div class="from-group" style="margin-top:5px;">
                            <label class="form-label"  >Property Amenities</label>
                            <div class="amenity-display" style="margin-top: -15px">

                            <span class="amenity" v-for="(item,i) in amenities" :key="item.id">
                                <v-checkbox
                                        v-model="main_form.amenities"
                                        :label="item.name"
                                        color="secondary"
                                        :value="item.id"
                                        hide-details
                                ></v-checkbox>
                            </span>
                            </div>
                        </div>

                    </v-stepper-content>
                    </v-form>
                    <v-stepper-content step="4" >
                            <Slider v-if="is_empty_photos"
                                animation="fade" :autoplay="false"
                                :duration="5000"
                                :speed="1000"
                                >
                                <SliderItem
                                    v-for="(file, index) in photo_list"
                                    :key="file.id"
                                    @click="actOnFile(file);"
                                   
                                >
                                <div class="pro_image" :style="{backgroundImage:'url('+file.filename+')'}"></div>
                               
                                </SliderItem>
                                </Slider>

                        <v-divider></v-divider>
                       
                         <FilePond ref="pond" allowMultiple="true" allowReorder="true"
                                  maxFileSize="1MB"
                                  maxTotalFileSize="10MB"
                                  maxFiles="8"
                                  labelMaxFileSizeExceeded
                                  accepted-file-types="image/jpeg, image/png,image/jpg"
                                  instant-upload="false"
                                  :files="myFiles"
                                  @init="handleFilePondInit"
                                  @onwarning="showWarning"
                                  required="true"
                                  v-on:updatefiles="handleFilePondUpdateFile"
                        />
                       
                       
                    </v-stepper-content>
                </v-stepper-items>

            </v-stepper>


            <v-bottom-navigation app :fixed=true  style="justify-content: space-evenly;padding-left:10px">
                <div style="height: 40px; width:50% ;padding:8px;">
                    <v-btn width="150"  @click="step--" v-if="step !== 1" class="offer_btn" large dark style=" border-radius: 5px;color: #379392 !important;background-color: #eff6f5 !important;">Back</v-btn>
                </div>
                <div style="height: 40px; width:50% ;padding:8px;">
                    <v-btn width="150"  :loading="apiloading"
                           :disabled="apiloading"
                           color="secondary" @click.prevent="proceedToNextStep()" class="inspection_btn" style=" border-radius: 5px;color: #fff !important;"> {{step===3?'Save & add Picture':'Continue'}}
                    </v-btn>
                </div>
            </v-bottom-navigation>


    </div>
<!--    <div class="b_radios">-->
<!--                                  <span v-for="(r,i) in [1,2,3,4,5,'6+']" :key="i">-->
<!--                                <input type="radio" name="bedroom" v-model="unit.bedrooms"  :value="r" :id="'bed'+ i+index" :rules="[rules.required]"  /><label class="radio text-center" :for="'bed'+i+index" style="width: 40px;height: 40px">-->
<!--                                    <span >{{r}}</span>-->
<!--                                </label>-->
<!--                                  </span>-->
<!--    </div>-->
</template>

<script>
    import vueFilePond from 'vue-filepond';
    import 'filepond/dist/filepond.min.css';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import {loadingMixin} from "../../mixins/Loading";
    import toast from "../../Services/ToastService";
   

    export default {
        name: "PropertyForm",
        mixins:[loadingMixin],
        props:{
            form_type:{type: String, required:true}
        },
        components: {
            FilePond: vueFilePond(FilePondPluginFileValidateType,FilePondPluginImagePreview)
        },
        data() {
            return {
                panel_value:[],
                myFiles:[],
                photo_list:[],
             
                upload_photo:{
                    photos:[]
                },
                unit_type_modal:false,
                step: 1,
                state_search:'',
                lga_search:'',
                counter:1,
                rules: {
                    required: value => !!value || "Required.",
                       phone:value=>{
                    const regre = /[0-9]|\./
                     return regre.test(value) || "Invalid number";
                 },
                },
                number_array:[1,2,3,4,5,"6+"],
                table_header:[
                    { text: 'Type'},
                    { text: 'Price', value: 'price' },
                    {text: 'Bed',value: 'bedrooms' },
                    { text: 'Bath', value: 'bathrooms' },
                    { text: 'Garage', value: 'garages' },
                    { text: 'Status', value: 'status' },
                    {text:'Action',value: 'action', sortable: false },
                    // { text: 'Action', value: 'data-table-expand', sortable: false },
                ],
                sales_status:[{text:'Available',value:3},{text:'Sold',value:1}],
                property_type:[],
                lgas:[],
                main_form:{
                    property_name:'',
                    is_estate:0,
                    property_size:'',
                    property_class_id:'',
                    state_id:'',
                    lga_id:'',
                    price:'',
                    property_city:'',
                    property_full_address: '',
                    property_description:'',
                    amenities:[],
                    home_type:[],
                    photos:[]
                },
                formal_amenities:[],
                unit_type:[],
                show_add_unit_btn:0,
                blockRemoval:true,
                showUnitTypeIsEmptyError:false,
                property_slug:'',
                property_id:'',
                inValid:false

            }
        },
        watch: {
            'main_form.property_class_id'(newValue, oldValue) {
                this.getPropertyTypeByClassId(newValue)
            },
            'main_form.state_id'(newValue, oldValue) {
                this.getLgaId(newValue)
            },
            unit_type () {
                this.blockRemoval = this.unit_type.length <= 1
            },
          

        },
        computed: {
            is_empty_photos(){
            return this.photo_list.length > 0
                
            },
            property_class() {
                return this.$store.state.general_module.all_property_class
            },
            states(){
                return this.$store.state.general_module.all_states;
            },
            amenities(){
              return this.$store.state.general_module.amenities;
            },
            property_status(){
                return this.$store.state.general_module.property_status;
            },
            show_selected_home_type(){
                this.property_type.filter((type)=>{
                    if(type.id === this.unit_type_form.home_type){
                        return type.name
                    }
                })
            },
        //    form operation
            show_add_unit(){
                let status = false
                if(this.show_add_unit_btn !==1){
                    status = false;
                }else{
                    status = true
                }
                return status;
            },
            expansion_panel_error(){
                return this.inValid===true;
            },
            show_plot_price(){
                     let status = false;
                if(this.main_form.property_class_id !== '' && this.main_form.property_class_id === 2 ){
                    status =  true;
                }else{
                    status = false
                }
                return status
            },
            show_add_unit_type(){
                let status = false;
                if(this.main_form.property_class_id !== '' && this.main_form.property_class_id === 1 ){
                    status =  true;
                }else{
                    status = false
                }
                return status
                // return this.main_form.property_class_id !== '' || this.main_form.property_class_id === 1;
            },
            unit_type_form_is_empty(){
                return this.main_form.home_type.length > 0;
            }
        },
        methods: {
            isEstateValue(e){
                this.show_add_unit_btn = e.target.value;
                console.log('is_estate',this.show_add_unit_btn);
            },
            addLine () {
                let form = {
                    id:this.counter++,
                    hometype:null,
                    bedrooms:null,
                    bathrooms:null,
                    garages:null,
                    price:null,
                    status:null,
                    is_sold:null
                }
                let checkEmptyLines = this.unit_type.filter(line => line.type === null)
                this.unit_type.push(form);
                // this.panel +=1


            },
            removeLine (lineId) {
                if (!this.blockRemoval) {
                    this.unit_type.splice(lineId, 1)
                }
            },
            handleFilePondInit: function() {
                // console.log('FilePond has initialized', this.$refs.pond);

                // FilePond instance methods are available on `this.$refs.pond`
            },
            handleFilePondUpdateFile(files){
                // console.log("file",files)
                this.myFiles = files.map(files=>files.file);
                
            },
            startUpload(){
                let formData = new FormData();
                for (let i = 0; i < this.myFiles.length; i++) {
                    // let reader = new FileReader()
                    // reader.readAsDataURL(this.myFiles[i])
                    // reader.onload = ()=> {
                    //     this.upload_photo.photos.push(reader.result);
                    // };
                    formData.append('photos[' + i + ']', this.myFiles[i]);
                }
                let form = {form:formData,property_id:this.property_id}
                   this.$store.dispatch("addNewOrUpdatePropertyPhotoAction",form).then((res)=>{
                    this.$router.replace({name:'mylisting'})
                })
            },
           
            savePropertyInfo(){
                if(!this.$refs.step_one_form.validate()){
                    toast.error("The required fields are missing")
                    this.step =1;
                    return;
                }
                this.main_form.home_type = this.unit_type;
                let data = {form:this.main_form,property_slug:this.property_slug}
                this.$store.dispatch("addNewOrUpdatePropertyAction",data).then((res)=>{
                    console.log('res',res)
                    this.property_id = res.id;
                    this.property_slug = res.slug;
                    this.step++
                })
            },
            showWarning(error){
                // error[, file, status]
            },
            getPropertyTypeByClassId(id) {
                this.$store.dispatch("getAllPropertyTypeAction",id).then((res)=>{
                    this.property_type = res;
                })
            },
            getLgaId(id){
                this.$store.dispatch("getAllStateLGA",id).then((res)=>{
                    this.lgas = res;
                })
            },
            openUnitTypeModal(){
              this.unit_type_modal=true;
            },
            closeUnitTypeModal(){
                this.unit_type_modal=false;
                this.$refs.unit_type_form_ref.reset();
            },
            proceedToNextStep(){
                switch (this.step) {
                    case 1:
                        if(this.unit_type.bedrooms ==='' ||this.unit_type.bathrooms ==='' || this.unit_type.price ===''|| this.unit_type.type ===''||this.unit_type.status ===''){
                            this.step = 1;
                            this.inValid = true
                            // toast.warning("Unit type ")
                        }else{
                            this.inValid = false
                            this.step++
                        }
                        break;
                    case 3:
                      this.savePropertyInfo();
                        break;
                    case 4:
                        this.startUpload();
                        break;
                    default:
                        this.step++;
                        break;
                }
            },
            getFormalUnitType(unit_type){
                if(unit_type !== undefined && unit_type !== null && unit_type.length){
                    unit_type.map((unit)=>{
                        this.unit_type.hometype = unit.hometype;
                        this.unit_type.bedrooms = unit.bedrooms;
                        this.unit_type.bathrooms = unit.bathrooms;
                        this.unit_type.garages = unit.garages;
                        this.unit_type.price = unit.price;
                        this.unit_type.is_sold = unit.is_sold;
                        this.unit_type.status = unit.status
                    })
                }
            },
            loadPropertyInfo(){
                

                if(this.form_type !== 'New'){
                    this.$store.dispatch("viewPropertyDetailAction",this.form_type);
                    let p =this.$store.state.property_module.property;
                     this.main_form.property_name=p.property_name
                    this.main_form.is_estate=p.is_estate
                    this.main_form.property_size = p.property_size
                    this.main_form.property_class_id=p.property_class_id
                    this.main_form.state_id=p.state_id
                    this.main_form.lga_id=p.lga_id
                    this.main_form.property_city=p.property_city
                    this.main_form.property_full_address = p.property_full_address
                    this.main_form.property_description=p.property_description
                    this.property_slug = p.slug;

                    let formal_amenities = p.amenity;
                    this.main_form.home_type = p.propertyprice
                    // this.getFormalUnitType(p.propertyprice)
                    this.unit_type = p.propertyprice
                    this.photo_list = p.propertyphoto
                    formal_amenities.map((amenity)=>{
                        this.main_form.amenities.push(amenity.amenityid)
                    })
                      console.log("pro_info",this.main_form)
                }else{
                   
                }
                return p;
            }



        },
        mounted() {
            this.addLine();
            this.loadPropertyInfo()
        }
    }
</script>

<style scoped>
    .v-stepper{
        border-radius: 0 !important;
        box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
        -webkit-box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    .v-stepper__header {
        box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
        -webkit-box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    .step_button{
        display: flex;
        justify-content: space-between;
    }
    .form-label{
        margin-top:-10px;
        font-size: 12px;
        color: #379392;
    }
    .form-group{
        padding-bottom:15px;
    }
    .v-text-field .v-input__control {
        height: 60px !important;
    }
    .b_radios{
        margin-top:5px;
        display: flex;
        flex-direction:row;
        width:100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    .b_radios input[type=radio]{
        display: none;
    }
    .b_radios label{
        /*border-radius: 8px;*/
        text-align: center;
        /*padding:10px;*/
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding:5px 10px;
        /*margin:5px 10px;*/
        font-size: 12px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50%;
    }
    .b_radios label span{
        padding:5px;
        color:rgba(74, 74, 74, 0.69);
        /*background-color: #379392;*/
    }
    .b_radios input[type=radio]:checked + .radio{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        background-color: #379392;
        /*color:#0077F3;*/
        color: #fff;
    }
    .b_radios input[type=radio]:checked + .radio span{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        background-color: #379392;
        /*color:#0077F3;*/
        color: #fff;
    }
    .amenity-display{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .amenity{
        width:50%;
        height: 60px;
        display: flex;
        padding:10px;
    }
    .file-selector {
        padding: 55px;
        font-weight: 600;
        background-color: #f9f9f9;
        color: #4e5b69;
        z-index: -9;
    }
    figure {
        margin: 0px;
    }

    .dropzone-container {
        display: flex;
        flex-direction: column;
        border: 1px dashed #ccc;
        border-radius: 15px;
    }
    invalid-panel{
        border:1px solid red ;
    }
    .pro_image_div{

    }
    .pro_image{
        background-size: cover;
        width:100%;
        height: 100%;
        background-position:center;
        background-repeat: no-repeat;
        /*border-radius: 10px;*/
    }
    .pro_image_two{
        background-size: cover;
        /*width:350px;*/
        /*height: 350px;*/
        background-position:center;
        background-repeat: no-repeat;
    }

/*    agile*/
    .main {
        margin-bottom: 5px;
    }

    .thumbnails {
        margin: 0 -5px;
        width: calc(100% + 10px);
    }

    .agile__nav-button {
        background: transparent;
        border: none;
        color: #ccc;
        cursor: pointer;
        font-size: 24px;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }
    .thumbnails .agile__nav-button {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .thumbnails .agile__nav-button--prev {
        left: -45px;
    }
    .thumbnails .agile__nav-button--next {
        right: -45px;
    }
    .agile__nav-button:hover {
        color: #888;
    }
    .agile__dot {
        margin: 0 10px;
    }
    .agile__dot button {
        background-color: #eee;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        width: 10px;
    }
    .agile__dot--current button, .agile__dot:hover button {
        background-color: #888;
    }

    .slide {
        -webkit-box-align: center;
        align-items: center;
        box-sizing: border-box;
        color: #fff;
        display: -webkit-box;
        display: flex;
        height: 450px;
        -webkit-box-pack: center;
        justify-content: center;
    }
    .slide--thumbniail {
        cursor: pointer;
        height: 100px;
        padding: 0 5px;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }
    .slide--thumbniail:hover {
        opacity: 0.75;
    }
    .slide img {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
        width: 100%;
    }
    .my-gallery img{
        width:320px;
    }
</style>