import {
    defaulturl
} from '@/Services/UrlSetting';
import {
    PropertyApi
} from '@/Services/PropertyApi'
import axios from 'axios'
import {Api} from "../../Services/ApiService";
const api = new PropertyApi(defaulturl);
const state = {
    next_page:'',
    next:2,
    path:''

};
const mutations = {
    SET_NEXT_PAGE(state,payload){
        state.next_page = payload
    },
    SET_NEXT(state,payload){
        state.next = payload
    },
    SET_PATH(state,payload){
        state.path = payload
    }
 
};
const actions = {
    nextPageAction({commit},page){
        commit("SET_NEXT_PAGE",page.next_page_url);
        commit("SET_NEXT",page.current_page+1);
    },
    loadMoreAction({ commit }, path_link) {
        let tobecut = defaulturl+"agentapp/";
        console.log("url",tobecut)
        console.log("path",path_link)
        let real_path = path_link.replace(tobecut,'');
        console.log("url",real_path)
        return new Promise((resolve, reject) => {
            // axios.get(real_path).then((res) => {
            api.loadMore(real_path).then((res) => {
                let p = res.data.data;
                resolve(p)
            })
        })
    },


        
};
const getters = {
    next_page:state=>state.next_page
};


const paginated_module = {
    state,
    mutations,
    actions,
    getters
};
export default paginated_module