<template>
    <div>
            <v-card elevation="0" class="section_height">
                <v-card-title  class="top_title">
                    <span class="title" style="width:80%">{{title}}</span>

                    <v-spacer></v-spacer>
<!--                    <a @click="seeAll()" class="see_all_link">See all</a>-->
                    </v-card-title>
                    <section class="property_scroller" >
                        <v-col style="padding:8px"
                            v-for="(card,i) in properties"
                            :key="i"
                            >
                            <v-card width="120px" height="120px" elevation="0" @click="showStateProperties(card.name)">
                                <div :style="{ backgroundImage: 'url(' + card.image + ')' }" class="property_img"> </div>
                              
                                    <v-card-title style="padding:5px;margin-top:2px">
                                        <span class="pro_title">{{card.name}}</span>
                                    </v-card-title>
                            </v-card>
                        </v-col>
                    </section>
                     
            </v-card>
    </div>
</template>
<script>
// import {propertyMixin} from '@/mixins/Property'
export default {
    // mixins:[propertyMixin],
    inject:["Bus"],
    props:['properties','title','subtitle','seeall'],
    
    methods: {
        seeAll(){
            switch(this.seeall){
                case'recent':
                break;
                case 'recommended':
                break;

            }
        },
        showStateProperties(state) {
            //    console.log(state)
            this.$store.dispatch("propertiesByStateAction",state);
            this.$store.dispatch("propertyListTypeAction",'capital');
            // this.Bus.$emit('selected_capital', state);
            this.$router.push({
                name: 'property_list',
            });
        },
    },

}
</script>
<style scoped>
    .section_height{
        height: 150px;
    }
    
    .see_all_link{
        text-decoration: none;
        font-size:10px;
        cursor: pointer;

    }
    .property_scroller{
        background-color: #fff;
        min-width: 100%;
        height: 120px;
        display: flex;
        overflow-x: auto;
        overflow-y:hidden;
    }
    .property_scroller::-webkit-scrollbar {
    display: none;
    }
    .property_img{
    background-size: cover;
    width:100%;
    height: 80px;
    background-position:center; 
    background-repeat: no-repeat;
    border-radius: 5px;
    }
    .property_img_top_btn{
        /* margin:5px 5px; */
        display: flex;
        z-index:1;
    }
    .property_img_top_btn .ma-2{
        color:white !important;
        height: 15px !important;
    }
    .pro_title{
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
    }
    .pro_subtitle{
            padding-top:5px;
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        font-size: 10px !important;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.47);
    }
</style>