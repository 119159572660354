import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import { getField, updateField } from 'vuex-map-fields';
import user_module from './modules/user_module';
import property_module from './modules/property_module';
import general_module from './modules/general_module'
import agent_module from  './modules/agent_module'
import paginated_module from "./modules/paginated_module";
import * as Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
    modules:{user_module,property_module,general_module, agent_module,paginated_module},
  plugins: [createPersistedState({
      storage:window.localStorage,
      reducer: (state) => ({ user_module: state.user_module
      }),
      //  storage: window.localStorage
    })]

})
