<template>
 
  <div id="app">

      <!-- <component :is="layout" ></component> -->
     <!-- <div v-if="networkStatus">Offline Status</div> -->
    <offline @detected-condition="handleConnectivityChange">
        <div slot="online">
        <component :is="layout"></component>
        </div>
        <div slot="offline">
        <!-- <p>You appear to be offline, that's okay, we can still do things..."</p> -->
        </div>
    </offline>
  </div>
</template>

<script>

import offline from "v-offline";
const frontend = "frontend";
export default {
  name: "App",
  components: { offline},
  data(){
      return{
          network_status:true,
            isEditing: false
      }
  },
  computed: {
    layout() {
      return this.network_status?(this.$route.meta.layout || frontend) + "-layout":'offline-layout';
    },
    // networkStatus: function () {
    //     console.log("network status", navigator.onLine)
    //       return navigator.onLine;

    //   }
  },
   methods:{
    preventNav(event) {
        if (!this.isEditing) return
        event.preventDefault()
        event.returnValue = ""
    },
    handleConnectivityChange(status) {
         this.network_status = status
         if(status===false){
               window.addEventListener("beforeunload", this.preventNav)
            this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
            })
         }
      console.log('network',status);
     
    },
    

   generalApis(){
         this.$store.dispatch("getAllStateAction");
   }
   },
  
    created() {
        this.generalApis();

    }
  
};
</script>
<style >
    @font-face {
        font-family: "Circular Std";
        font-style: normal;
        src: url('./assets/fonts/CircularStd-Medium.otf');
    }
    body{
        font-family: Circular Std;
        font-style: normal;
    }
    /*@import url("./assets/css/general.css");*/

    .v-divider{
        /* margin:30px 0 10px 0 !important; */
    }
    #app{
       font-family: Circular Std !important;
    }
    a{
        text-decoration: none;
    }
    .a-link{
        color:#379392;
        text-decoration: none;
    }
    .v-application .title {
        font-family: Circular Std !important;
    }
    .v-card__title {
        font-family: Circular Std !important;
        font-style: normal;
    }
    .mycontainer{
      width:100% !important;
    }
      .v-slider__thumb {
        width: 20px !important;
        height: 20px !important;
      }
      .v-slider__thumb:before {
          left:-8px !important;
          top:-8px !important;
      }
    .my-divider{
        padding:0 12px;
    }

/*    bottom Nav*/
    .mynavbar{
        box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.05);
    }
    .break-word{
        white-space:pre-wrap;
        word-break:break-word;
        /*overflow-wrap: break-word;*/
        /*word-wrap: break-word;*/

        /*-webkit-hyphens:auto;*/
        /*-ms-hyphens: auto;*/
        /*-moz-hyphens: auto;*/
        /*hyphens: auto;*/
    }
    /*    form*/

    .radios{
        margin-top:-5px;
        display: flex;
        flex-direction:row;
        width:100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    .radios input[type=radio]{
        display: none;
    }
    .radios label{
        border-radius: 8px;
        text-align: center;
        /*padding:10px;*/
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin:5px 10px;
        font-size: 12px;
        background-color: #F8F8F8;
    }
    .radios label span{
        padding:5px;
        color:rgba(74, 74, 74, 0.69);
    }
    .radios input[type=radio]:checked + .radio{
        background-color:  rgba(0, 119, 243, 0.05);
        color:#0077F3;
    }
    .radios input[type=radio]:checked + .radio span{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        color:#0077F3;
    }
    .select-icon{
        background-color:  rgba(0, 119, 243, 0.05);
        border-radius: 50%;
    }
/*   divier */
    hr {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        border: 0;
        border-top: 1px solid rgba(0,0,0,.1);
    }
    .v-stepper__wrapper {
     overflow: visible !important;
    }

</style>