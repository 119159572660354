<template>
    <div>
    
             <div class="outter">
                <div @click="viewDetail(property)"  class="property_img" :style="{ backgroundImage: 'url(' + property.property_cover_image + ')' }"></div>
                <div class="property_info">
                    <div class="property_info_detail">
                        <v-chip class="ma-2 price" color="secondary" label >{{property.property_price | currency}}</v-chip>
                        <p class="property_title break-word" @click="viewDetail(property)">{{property.property_name | shortNameBy40}}</p>
                        <span class="property_location">{{property.property_city}}, {{property.property_state}}</span>
                       <v-divider style="margin:10px 25px 0 10px;"></v-divider>
                        <v-card-subtitle class="amenity_container pro_subtitle">
                            <template  class="amenity_template">
                                {{property.property_bedrooms}}
                                <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/beds.png')+')' }"></div>
                            </template>

                            <template class="amenity_template">
                                {{property.property_bathrooms}}
                                <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/baths.png')+')' }"></div>
                            </template>
                            <template class="amenity_template">
                                {{property.property_size}}
                                <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/size.png')+')' }"></div>
                            </template>
                            <!-- <span class="pro_subtitle"> {{property.property_bedrooms}} bd. {{property.property_bathrooms}} bth. {{property.property_size}}sm</span> -->
                        </v-card-subtitle>

                    </div>
                    <v-btn icon class="mytheme_btn_background" width="30" height="30" style="margin:15% 0" @click="viewDetail(property)">
                        <v-icon size="25" color="primary">mdi-chevron-right</v-icon>
                    </v-btn>

                
                     
                        
                </div>
                    
            </div>
        
       
    </div>
</template>
<script>
import {propertyMixin} from '@/mixins/Property'
export default {
    mixins:[propertyMixin],
    props:['property'],
}
</script>
<style scoped>
    .outter{
        width:100%;
        padding:5px;
        height: 120px;
        /*border: 1px solid #F2F2F2;*/
        box-sizing: border-box;
        /*border-radius: 10px;*/
        display:flex;
        justify-content: space-between;
        margin-bottom: 25px;
    }
    .property_img{
        background-size: cover;
        width:35%;
        height: 115px;
        background-position:center; 
        background-repeat: no-repeat;
        border-radius: 10px;
    }
    .property_info{
        /*margin:0 5px;*/
        width:65%;
        display: flex;
        justify-content: flex-start;
    }
    .property_info_detail{
        width:85%;
        height:200px;
        display: flex;
        flex-direction: column;
    }
    .property_info_detail .price{
        width:120px;
        text-align: center;
        height: 25px;
        font-size: 12px;
    }
    .property_title{
        font-size:12px;
        padding:0 10px;
        line-height: 14px;
        color:#4A4A4A;
        font-weight: normal;
        font-style: normal;
    }
    .property_location{
        font-size:9px;
        color:rgba(0,0,0,0.47);
        padding:0 10px;
        margin-top:-10px;
        margin-bottom: 5px;
    }
    .amenity_container{
        display: flex;
        align-items: center;
        align-content: space-between;
        padding:0 10px;
        /* justify-content: center; */
    }
    .pro_subtitle{
        padding-top:-10px !important;
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.47);
    }
    .amenity_template{
        margin:0 12px;
    }
    .bbs_div{
         background-size: cover;
         margin-right:10px;
         margin-left: 5px;
        width:9px;
        height: 9px;
        background-position:center; 
        background-repeat: no-repeat;
        /* border-radius: 10px; */
    }


     .pro_title2{
       
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 11px;
    }
    .price{
        font-family: Circular Std;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 11px;
    }

    .n-chip{
        height:30px;
    }
        .seperator{
    position: relative;
    width: 70%;
    height: 0px;
    left: 0px;
    margin-top:1px;
    /* top: 300px; */
    border: 1px solid rgba(0, 0, 0, 0.05);
}
</style>