<template>
    <div>
       
       <div style="width:100%; height:180px;margin:10px 0">
          <video width="100%" height="200" controls muted>
                <source :src="video" type="video/mp4">
            </video>
       </div> 
         <!-- <div class="carousel-div">
            <v-carousel
                    :continuous="false"
                    :cycle="cycle"
                    :show-arrows="false"
                    hide-delimiter-background
                    :hide-delimiters="true"
                    delimiter-icon="mdi-minus"
                    height="150"
                    width="390"
                    class="my-carousel"
                    color="primary"
            >
                <v-carousel-item>
                    <div class="account-setup">
                        <v-subheader class="account-setup-subhead">Complete Account Setup</v-subheader>
                        <v-row justify="center" class="account-setup-row">
                            <v-col cols="7">
                                <span class="account-setup-text">Complete your account set up to get the most out of REMS. Go to setting/profile to update your profile to help us serve  you better</span>
                            </v-col>
                            <v-col cols="5">
                                <v-icon size="60" color="black">mdi-clipboard-account-outline</v-icon>
                            </v-col>
                        </v-row>
                    </div>
                </v-carousel-item>
                <v-carousel-item v-for="(item, i) in items" :key="i">
                    <div class="promo-img" :style="{backgroundImage:'url('+item.src +')'}"></div>
                </v-carousel-item>

            </v-carousel>
                <div class="text-center advert-dot">
                        <v-icon color="primary"  size="20">mdi-minus</v-icon>
                    <v-icon color="grey" style="margin-left:-5px" size="20">mdi-dots-horizontal</v-icon>
                </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        name: "HomePromoCard",
      
        data() {
            return {
                video:'https://app.newhomes.ng/video/woodland_estate.mp4',
                items: [
                    // {
                    //     src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                    // },
                    // {
                    //     src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                    // },
                    // {
                    //     src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                    // },
                    // {
                    //     src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                    // },
                ],
                colors: [
                    'green',
                    'secondary',
                    'yellow darken-4',
                    'red lighten-2',
                    'orange darken-1',
                ],
                cycle: true,
                slides: [
                    'First',
                    'Second',
                    'Third',
                    'Fourth',
                    'Fifth',
                ],
            }
        },
    }
</script>

<style scoped>
    .carousel-div{
        padding:15px;
    }
   .account-setup{
       width:390px;
       height: 120px;
       background-color: rgba(0, 117, 242, 0.05);
       box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
   }
   .account-setup-row{
       padding:10px;
       margin-top:-30px;
   }
   .account-setup-subhead{
       color:#0077F3;
   }
   .account-setup-text{
       font-size: 8px;
       white-space: initial;
       line-height: 10px !important;
   }
    .promo-img{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width:100%;
        height: 120px;
    }
    .advert-dot{
        margin-top:-20px;
    }
</style>