import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import filter from "@/plugins/filter"
import component from "./components/component";
import VueSwal from 'vue-swal'
import Paystack, { setGlobalConfig } from "paystack-simple";
import VueTelInputVuetify from 'vue-tel-input-vuetify';
import { TokenService } from "./Services/TokenService";
import { ApiSetUp } from "./Services/ApiService";
Vue.use(VueTelInputVuetify,{vuetify});
import CanvasVideo from 'vue-canvasvideo'
import 'vue-canvasvideo/dist/vuecanvasvideo.min.css'
import vueScrollto from 'vue-scrollto'

Vue.use(vueScrollto)

Vue.use(CanvasVideo)

import EasySlider from 'vue-easy-slider'

Vue.use(EasySlider)

Vue.config.productionTip = false;
import "@mdi/font/css/materialdesignicons.min.css";



Vue.use(VueSwal);
setGlobalConfig({ key: 'pk_test_89710b77b2df8b41c7991ebd487146efce5dc7bb' }) // Key has been set globally
const paymentInstance = Paystack();
paymentInstance.init();

if (TokenService.getToken()) {
  ApiSetUp.setHeader()
  
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data: {
    Bus:new Vue()
  },
  provide: function () {
    return {
       Bus: this.Bus,
      paystack:paymentInstance,
    }
   
  }
}).$mount("#app");