<template>
    <div>
        <div class="property_subscription-promo-card">
            <div class="column">
                <div class="property_subscription_img" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
                <div class="right-side">
                    <table class="mytable">

                            <tbody>
                            <tr class="mytr">
                                <td class="first-column">Views</td>

                                <td class="second-column"><span class="float-right">{{click_insight}}</span></td>

                            </tr>

                            <tr class="mytr">
                                <td >Clicks</td>
                                <td><span class="float-right">{{view_insight}}</span></td>
                            </tr>
                            <tr >
                                <td >Placement</td>
                                <td><span class="">Homepage.Connect.Chat</span></td>
                            </tr>
                            </tbody>
                    </table>
                </div>
            </div>
            <div class="prorow">
                <v-btn class="status-label" :class="[status===1?'active-label':'inactive-label']" style="font-size:10px"  depressed small text  >{{status===1?'Running':'Ended'}}</v-btn>
                    <v-btn icon class="mytheme_btn_background" width="30" height="30" @click="goToPromotionPage('promotion_insight')">
                        <v-icon size="20" color="primary">mdi-chevron-right</v-icon>
                    </v-btn>
            </div>

        </div>
        <v-divider></v-divider>

    </div>
</template>

<script>
    export default {
        name: "PropertyPromoCard",
        props:{
          property_subscription:{type:Object}
        },

        data() {
            return {
                test_img: "https://randomuser.me/api/portraits/women/81.jpg",

                pro_data:[
                    {text:"Views",count:"6,000"},
                    {text:"Clicks",count:"4,500"},
                    {text:"Placement",count:"Homepage.Connect.Chat"}
                ]
            }
        },
        computed: {
            status() {
                if(this.property_subscription !==null) {
                    return this.property_subscription.status;
                }
                return 0
            },
            image(){
                if(this.property_subscription.property !==null){
                   return this.property_subscription.property.property_cover_image
                }
                return ''
            },
            click_insight(){
                if(this.property_subscription!==null){
                    return this.property_subscription.click;
                }
                return 0;
            },
            view_insight(){
                if(this.property_subscription!==null){
                    return this.property_subscription.view;
                }
                return 0;
            }
        },
        methods: {
            goToPromotionPage(page_name) {
                let property_id = this.property_subscription.property.id
                return this.$router.push({name:page_name,params:{property_id:property_id}});
            }
        },
        created() {
            console.log(this.property_subscription)
        }
    }
</script>

<style scoped>
    .property_subscription-promo-card{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    .column{
        display: flex;
        justify-content: flex-start;
    }
    .column .property_subscription_img{
        background-size: cover;
        width:30%;
        height: 105px;
        background-position:center;
        background-repeat: no-repeat;
        border-radius: 10px;
    }
    .column .right-side{
        width: 70%;
        padding:10px;

    }
    .prorow{
        display: flex;
        justify-content: space-between;
    }
    .mytable td {
        font-size:11px;
        height: 30px;
        width: 100%;
        color:rgba(0,0,0,0.47);
        letter-spacing: 0.00252px;
        /*height:25px !important;*/
        /*width:80px;*/

    }
    .mytr td{
        border-bottom: 1px solid rgba(0,0,0,0.47);
        margin-top:5px;
        margin-bottom: 5px;
    }
    .status-label{
        font-size:10px;
        min-width:100px !important;
        overflow-wrap: break-word;
        min-height: 20px;
        white-space: initial;
    }
    .active-label{
        background-color: rgba(0, 119, 243, 0.05);
        color:#0077F3;
    }
    .inactive-label{
        background-color: #F8F8F8;
        color:#808080 !important;
    }

</style>