import {
    defaulturl
} from '@/Services/UrlSetting';
import {
    GeneralApi
}
from '../../Services/GeneralApi'
const api = new GeneralApi(defaulturl);
const state = {
    all_states: [],
    all_selected_state_lga:[],
    all_property_types:[],
    all_property_class:[],
    property_status:[],
    amenities:[],
    drawer:false,
    promo_plan:[],
    duration_promotion:[],
    user_wallet:''
}
const mutations = {
    GET_ALL_STATE(state, payload) {
        state.all_states = payload;
    },
    GET_ALL_SELECTED_STATE_LGA(state,payload){
        state.all_selected_state_lga = payload;
    },
    GET_ALL_PROPERTY_CLASS(state,payload){
        state.all_property_class = payload
    },
    GET_PROPERTY_TYPE(state,payload){
        state.all_property_types=payload;
    },
    GET_PROPERTY_STATUS(state,payload){
        state.property_status=payload;
    },
    GET_AMENITIES(state,payload){
        state.amenities=payload;
    },
    TOGGLE_DRAWER(state,payload){
        state.drawer = payload;
    },
    GET_ALL_PROMO_PLAN(state,payload){
        state.promo_plan=payload
    },
    GET_PROMOTION_DURATION(state,payload){
        state.duration_promotion=payload
    },
    GET_USER_WALLET(state,payload){
        state.user_wallet = payload
    }
}
const actions = {
    toggleDrawerAction({commit},slug){
        commit("TOGGLE_DRAWER",slug)
    },
    getAllStateAction({commit}) {
        api.states().then((res) => {
            let s = res.data.data;
            commit("GET_ALL_STATE", s);
        })
    },
    getAllStateLGA({commit},state_id){
        return new Promise((resolve,reject)=>{
            api.all_local_govt(state_id).then((res)=>{
               let lga= res.data.data;
               commit("GET_ALL_SELECTED_STATE_LGA",lga)
               resolve(lga);
            });
        })
    },
    getAllPropertyClassAction({commit}){
        api.property_class().then((res)=>{
            let r =  res.data.data;
            commit("GET_ALL_PROPERTY_CLASS",r);
        })
    },
    getAllPropertyTypeAction({commit},class_id){
        return new Promise((resolve,reject)=> {
            api.propertyTypes(class_id).then((res) => {
                let r = res.data.data;
                commit("GET_PROPERTY_TYPE", r)
                resolve(r);
            })
        })
    },
    getAmenitiesAction({commit}){
        api.getAmenities().then((res)=>{
            let t = res.data.data;
            commit("GET_AMENITIES", t)

        })
    },
    getAllPropertyStatusAction({commit}){
        api.getPropertyStatus().then((res)=>{
            let t = res.data.data;
            commit("GET_PROPERTY_STATUS", t)

        })
    },
    getAllPromoPlanActon({commit}){
        api.promotionPlan().then((res)=>{
            let r = res.data.data;
            commit("GET_ALL_PROMO_PLAN",r)
        })
    },
    getAllPromoDurationAction({commit}){
        api.promotionDuration().then((res)=>{
            let p = res.data.data;
            commit("GET_PROMOTION_DURATION",p);
        })
    },
    addUserPayment({commit},form){
        return new Promise((resolve,reject)=>{
            api.addpayment(form).then((res)=>{
                let r = res.data.data;
                resolve(r);
            })
        })
    },
    getUserWalletAction({commit}){
        api.userWallet().then((res)=>{
            let p = res.data.data;
            commit("GET_USER_WALLET",p);
        })
    }
}
const getters = {
    property_types:state=>state.all_property_types,
    all_promo_plan:state=>state.promo_plan
}

const general_module = {
    state,
    mutations,
    actions,
    getters
}

export default general_module