import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/frontendviews/Login';
import backend_routes from './backend_routes';
import EventBus from '../Services/Event';
import { TokenService} from '../Services/TokenService';


Vue.use(VueRouter)

const front_routes = [
  {
    path: '/login',
    name: 'login',
    meta:{
      layout: 'frontend',
      backRoute: false,
      public:true,
      onlylogout:true,
      title:""
    },
    component: ()=> import(/* webpackChunkName: "login" */'@/views/frontendviews/Login')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      layout: 'frontend',
      backRoute: true,
      public: true,
      onlylogout: true,
      title:"Register"
    },
    component: () => import(/* webpackChunkName: "login" */'@/views/frontendviews/Register')
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: {
      layout: 'frontend',
      backRoute: true,
      public: true,
      onlylogout: true,
      title:"Password"
    },
    component: () => import(/* webpackChunkName: "forgot" */'@/views/frontendviews/Forgot')
  },

  

  { path: '/', redirect: { name: 'login' } },
  {
    path: '*',
    name: 'page404',
    meta: {
      layout: 'frontend',

      public: true,
      onlylogout: true
    },
    component: () => import(/* webpackChunkName: "page404" */'@/views/frontendviews/Page404')
  },
];
const routes = front_routes.concat(backend_routes)
const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  // base: process.env.BASE_URL,
  routes,
    scrollBehavior(to,from,savedPosition) {
      // window.scrollTo(0, 0);
      return {x:0,y:0}
    }
});
router.beforeResolve((to, from, next) => {
  if (to.name) {
    // NProgress.start();
    EventBus.$emit("loading", true)
  }
  next();
});
router.afterEach((to, from) => {
  // NProgress.done();
  localStorage.setItem('last_visited_route', to.name);
  EventBus.$emit("loading", false)
});
router.beforeEach((to, from, next) => {

  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlylogout)
  // const user = store.state.user_module.user;beforeEnter: userIsVerify
  let loggedIn = !!TokenService.getToken();
  if (!isPublic && !loggedIn && to.path !== '/login') {
    return next({
      path: '/login',
      query: {
        redirect: to.fullPath
      } // Store the full path to redirect the user to after login
    });

  }
  
  


    if (loggedIn && onlyWhenLoggedOut) {
        return next('/home') 
  
    }
  next();
});
export default router
