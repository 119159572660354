import {defaulturl} from '@/Services/UrlSetting';
import {AgentApi} from "../../Services/AgentApi";
const api = new AgentApi(defaulturl);
// const api = new Api();
const state = {
    all_agents:[],
    all_developers:[],
    agent_list_type:"all",
    agent_search_result:[],
    agent_profile:{},
    friend_list:[],
    agent_properties_by_id:[],
    compare_user_list:[],
    compare_property_list:[],
    properties_promotion_list:'',
    property_insight:{}

};
const mutations = {
    GET_COMPARE_FRIEND_LIST(state,payload){
        state.compare_user_list = payload
    },
    GET_COMPARE_PROPERTY_LIST(state,payload){
        state.compare_property_list = payload
    },
    AGENT_LIST_TYPE(state,payload){
        state.agent_list_type = payload;
    },
    GET_ALL_AGENT(state, payload) {
        state.all_agents = payload
    },
    GET_ALL_DEVELOPER(state, payload) {
        state.all_developers = payload;
    },

    AGENT_SEARCH_RESULT(state,payload){
        state.agent_search_result =payload
    },
    AGENT_PROFILE(state,payload){
        state.agent_profile = payload;
    },
    FRIEND_LIST(state,payload){
        state.friend_list = payload
    },
    AGENT_PROPERTIES_BY_ID(state,payload){
        state.agent_properties_by_id = payload
    },
    GET_PROPERTIES_PROMOTION_LIST(state,payload){
        state.properties_promotion_list = payload
    },
    GET_PROPERTY_INSIGHT(state,payload){
        state.property_insight = payload
    }
 
};
const actions = {
    agentListTypeAction({commit},type){
        commit("AGENT_LIST_TYPE",type);
    },

    allAgentAction({ commit }) {
        api.allAgents().then((res) => {
            let p = res.data.data;
            commit("GET_ALL_AGENT", p);
        })
    },
    allDeveloperAction({ commit }) {
        api.allDevelopers().then((res) => {
            let p = res.data.data;
            commit("GET_ALL_DEVELOPER", p);
        })
    },
    allCompareUserList({commit}){
        api.compareFriendList().then((res)=>{
            let r = res.data.data;
            commit("GET_COMPARE_FRIEND_LIST",r);
        })
    },
    allComparePropertyList({commit}){
        api.comparePropertyList().then((res)=>{
            let r = res.data.data;
            commit("GET_COMPARE_PROPERTY_LIST",r);
        })
    },

    searchAgentAction({commit},searchform){
        return new Promise((resolve,reject)=>{
            api.searchAgent(searchform).then((res)=>{
                let r = res.data.data;
                commit("AGENT_SEARCH_RESULT",r)
                resolve(r);
            })
        })
    },

    viewAgentDetailAction({ commit }, slug) {

        api.viewAgentProfile(slug).then((res) => {
            let p = res.data.data;
             commit("AGENT_PROFILE", p);
        })
    },
    getAllfriendAction({commit}){
        api.friendList().then((res)=>{
            let p = res.data.data;
            commit("FRIEND_LIST",p)
        })
    },
    addFriendAction({commit},form){
        let r = form.user_id+'/'+form.friend_id;
        return new Promise((resolve,reject)=>{
            api.addFriend(r).then((res)=>{
                let p = res.data.data;
                resolve(p);
            })
        })
    },
    agentPropertiesById({commit}){
        api.agentPropertiesById().then((res)=>{
            let p = res.data.data;
            commit("AGENT_PROPERTIES_BY_ID",p)
        })
    },
    addAgentSubscriptionAction({commit},form){
        return new Promise((resolve,reject)=>{
            api.addAgentSubscription(form).then((res)=>{
                let p = res.data.data;
                resolve(p);
            })
        })
    },
    getPropertiesPromotionList({commit}){
            api.propertiesPromotionList().then((res)=>{
                let p = res.data.data;
                commit("GET_PROPERTIES_PROMOTION_LIST",p)
            })
    },
    getPropertyInsight({commit},property_id){
        api.propertyPromotionInsight(property_id).then((res)=>{
            let p = res.data.data;
            commit("GET_PROPERTY_INSIGHT",p)
        })
    },

        
};
const getters = {
    agent_list_type: state => state.agent_list_type,
    all_agents:state=>state.all_agents,
    user_compare_list:state=>state.compare_user_list,
    property_compare_list:state=>state.compare_property_list
};


const agent_module = {
    state,
    mutations,
    actions,
    getters
};
export default agent_module