import Vue from 'vue';
import { VueEditor } from "vue2-editor";
import {VueAgile} from 'vue-agile'
import VuePictureSwipe from 'vue-picture-swipe';
//layout componenent
import Frontend from '@/views/layouts/Frontend';
import Backend from '@/views/layouts/Backend';
import Offline from '@/views/layouts/Offline';


const  NavList = ()=> import(/* webpackChunkName: "navigation" */'./navigation/NavList')
const  BottomNav = ()=> import(/* webpackChunkName: "navigation" */'./navigation/BottomNav')
const  MyBottomSheet = ()=> import(/* webpackChunkName: "navigation" */'./navigation/MyBottomSheet')

const Seperator = () => import(/*webpackChunkName:"general" */'./general/Seperator');
const PageTitle = () => import(/*webpackChunkName:"general" */'./general/PageTitle');
const MyListingCard = () => import(/*webpackChunkName:"general" */'./general/MyListingCard');
const MyCollectionCard = () => import(/*webpackChunkName:"general" */'./general/MyCollectionCard');
const EmptyList = ()=> import(/*webpackChunkName:"general"*/"./general/EmptyList")
// const MySkeleton = () => import(/*webpackChunkName: "general"*/'./general/MySkeleton')
import MySkeleton from "./general/MySkeleton";

import PropertyListCard from './property/PropertyListCard.vue'
const Success = () => import(/*webpackChunkName:"general" */'./general/Success');



const SearchArea = () => import(/*webpackChunkName:"search_area" */'./search_area/SearchArea');
const SearchSectionTitle = () => import(/*webpackChunkName:"search_area" */'./search_area/SearchSectionTitle');
const ProfileListCard = () => import(/*webpackChunkName:"profile" */'./profile/ProfileListCard');
const ProfileDetail = () => import(/*webpackChunkName:"profile" */'./profile/ProfileDetail');

import HomePropertyCard from "./property/HomePropertyCard";
// const HomePropertyCard = () => import(/*webpackChunkName:"property" */'./property/HomePropertyCard');
import HomePromotedCard from "./property/HomePromotedCard";
import HomeAdvertCard from "./adverts/HomeAdvertCard";
import ByLocationCard from './property/ByLocationCard.vue'
import PropertyPromoCard from "./promotion/PropertyPromoCard";
import BigPropertyCard from "./property/BigPropertyCard";
import PropertyFrom from "./property/PropertyForm";

const TourForm = ()=> import(/*wenpackChunkName : "property"*/'./propertyview/TourForm')
const OfferForm = ()=> import(/*wenpackChunkName : "property"*/'./propertyview/OfferForm')


// vendor components
Vue.component('my-vue-editor',VueEditor)
Vue.component('agile',VueAgile)
Vue.component('vue-picture-swipe', VuePictureSwipe);

Vue.component('frontend-layout',Frontend);
Vue.component('backend-layout', Backend);
Vue.component('offline-layout', Offline);


Vue.component('seperator', Seperator);
Vue.component('page-title', PageTitle);
// Vue.component('paginated', Pagination);
Vue.component('success', Success);
Vue.component('skeleton-loader', MySkeleton);
Vue.component("empty-list",EmptyList)
// nav
// Vue.component('app-bar', AppBar);
Vue.component('nav-list', NavList);
Vue.component('bottom-nav', BottomNav);
Vue.component('my-bottom-sheet', MyBottomSheet);
//search area
Vue.component("search-area",SearchArea);
Vue.component("search-section-title",SearchSectionTitle);

// profile list
Vue.component("profile-list-card",ProfileListCard);
Vue.component("profile-detail",ProfileDetail);

//property
Vue.component("home-property-card",HomePropertyCard)
Vue.component("home-promoted-card",HomePromotedCard)
Vue.component("home-advert-card",HomeAdvertCard)
Vue.component("big-property-card",BigPropertyCard)
Vue.component("property-form",PropertyFrom)
//promotion
Vue.component("property-promo-card",PropertyPromoCard)

Vue.component('by-location-card', ByLocationCard);//ohome page
Vue.component('mylistingcard', MyListingCard);
Vue.component('mycollectioncard', MyCollectionCard);
Vue.component('property-list-card', PropertyListCard);

// property view

Vue.component('property-tour-form', TourForm);
Vue.component('property-offer-form', OfferForm);
