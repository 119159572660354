<template>
    <div>
        <v-card elevation="0" class="section_height" v-if="!apiloading">
            <v-card-title class="top_title">
                <span class="title" style="width:85%">{{title}}</span>

                <v-spacer></v-spacer>
<!--                <v-icon>mdi-dots-horizontal</v-icon>-->
                <v-icon color="primary"  size="20">mdi-minus</v-icon>
                <v-icon color="grey" style="margin-left:-5px" size="20">mdi-dots-horizontal</v-icon>
            </v-card-title>

            <section class="featured_card">
                <v-col style="padding:8px" v-for="(property,index) in properties" :key="property.id">
                    <big-property-card width="298" :property="property" :show-save-icon="true"></big-property-card>

                </v-col>

            </section>
        </v-card>
        <div  style="display: flex;justify-content: flex-start; width:100%"  v-if="apiloading">
            <skeleton-loader type="card" :width=298 :height=220  v-for="i in 5" :key="i" style="margin:0 10px" ></skeleton-loader>
        </div>
    </div>
</template>

<script>
    import {loadingMixin} from "../../mixins/Loading";

    export default {
        name: "HomePromotedCard",
        props:['properties','title'],
        mixins:[loadingMixin],
        methods: {

        },
    }
</script>

<style scoped>
    .section_height{
        height: 270px;
    }

    .featured_card {
        background-color: #fff;
        min-width: 100%;
        height: 230px;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .featured_card::-webkit-scrollbar{
        display: none;
    }
    .promoted{
        font-size: 12px;
    }
    .save_btn{
        z-index:500;
        position: absolute;
        right:15px;
    }


    .featured_properties_section {
        width: 100%;
        height: 270px;
    }

    .top_title {
        padding: 8px !important;
    }

    .featured_card {
        background-color: #fff;
        min-width: 100%;
        height: 230px;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .featured_card::-webkit-scrollbar{
        display: none;
    }

    .card--content {
        background-color: #e74c3c;
        min-width: 226px;
        margin: 5px;
    }

    .card::-webkit-scrollbar {
        display: none;
    }

    .featured_property_img {
        background-size: cover;
        width: 100%;
        height: 140px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
    }

    .property_img_top_btn {
        /* margin:5px 5px; */
        display: flex;
        z-index: 10;
    }

    .property_img_top_btn .ma-2 {
        color: white !important;
        height: 25px !important;
        font-weight: bold;
    }
    .property-price{
        font-family: Circular Std;
        font-style: normal;
        font-weight: 900;
        font-size: 17px;
        line-height: 22px;
        /* identical to box height */

        letter-spacing: 0.06em;

        color: #000000;
    }
    .property-name{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top:1px;
        padding:4px;
    }
    .subtitle {
        color: rgba(0, 0, 0, 0.47);
        font-size: 11px;
        white-space: initial;
        line-height: 12px;

    }
    .status-label{
        background-color: rgba(0, 119, 243, 0.05);
        color:#0077F3;
        font-size:10px;
        min-width:120px;
        overflow-wrap: break-word;
        min-height: 20px;
        white-space: initial;
        /*font-wi*/
    }
</style>