import store from '../store/index'
const userIsVerify = (to, from, next)=>{
    if(store.getters.userIsVerified !== 1){
        next('/verify-email')
        return;
    }
    next()
  

}
const backend_routes = [
    {
        path:'/verify-email',
        name:'verify_mail',
        meta:{
            layout: "frontend",
            backRoute: false,
            title:"Verify Email"
        },
        component:()=>import(/* webpackChunkName: "home" */'../views/backendviews/Verify')
    },
    {
        path:'/home',
        name:'home',
        meta:{
            layout: "backend",
            backRoute: false,
            title:""
        },
        component:()=>import(/* webpackChunkName: "home" */'../views/backendviews/bottom_menu/Home'),
        beforeEnter: userIsVerify
    },
    {
        path: '/property-list',
        name: 'property_list',
        meta: {
            layout: "backend",
            backRoute: true,
            title: "Properties"
        },
        component: () => import(/* webpackChunkName: "property" */'../views/backendviews/bottom_menu/PropertyList'),
        beforeEnter: userIsVerify
    },
    {
        path: '/connect',
        component: () => import(/* webpackChunkName: "connect" */'../views/backendviews/bottom_menu/connect/Connect'),
        children: [
            {path:'/connect/list',
            name:"connect_list",
            meta: {
                layout: "backend",
                backRoute: true,
                title: "Connect"
                },
                component:()=>import(/* webpackChunkName: "connect" */'../views/backendviews/bottom_menu/connect/ConnectList'),
                beforeEnter: userIsVerify
            },
            {
                path:'/connect/profile/:slug',
                name:"connect_profile",
                meta: {
                    layout: "backend",
                    backRoute: true,
                    title: "Connect"
                },
                component:()=>import(/* webpackChunkName: "connect" */'../views/backendviews/bottom_menu/connect/ConnectProfile'),
                beforeEnter: userIsVerify
            },
            {
                path:'/connect/friend',
                name:"connect_friend",
                meta: {
                    layout: "backend",
                    backRoute: true,
                    title: "My Connections"
                },
                component:()=>import(/* webpackChunkName: "connect" */'../views/backendviews/bottom_menu/connect/FriendList'),
                beforeEnter: userIsVerify
            },
            {
                path:'',
                redirect: 'connect/list',
                meta: {
                    layout: "backend",
                    backRoute: true,
                    title: "Connect"
                }
            },

        ]

    },
    {
        path: '/promotion',
        component: () => import(/* webpackChunkName: "promotion" */'../views/backendviews/bottom_menu/promo/Promotion'),
        children: [
            {   path: '/promotion/promotion-list',
                name: 'promotion_list',
                meta: {
                    layout: "backend",
                    backRoute: true,
                    title: "Promotions"
                },
                component: () => import(/* webpackChunkName: "promotion" */'../views/backendviews/bottom_menu/promo/PromotionList'),
                beforeEnter: userIsVerify
            },
            {   path: '/promotion/promotion-insight/:property_id',
                name: 'promotion_insight',
                meta: {
                    layout: "backend",
                    backRoute: true,
                    title: "Promotion Insights"
                },
                component: () => import(/* webpackChunkName: "promotion" */'../views/backendviews/bottom_menu/promo/PromotionInsight'),
                beforeEnter: userIsVerify
            },
            {   path: '/promotion/promotion-create',
                name: 'promotion_create',
                meta: {
                    layout: "backend",
                    backRoute: true,
                    title: "New Promotion"
                },
                component: () => import(/* webpackChunkName: "promotion" */'../views/backendviews/bottom_menu/promo/PromotionCreate'),
                beforeEnter: userIsVerify
            },
            {   path: '',
                redirect:"promotion/promotion-list",
                meta: {
                    layout: "backend",
                    backRoute: true,
                    title: "Promotions"
                },
            }

        ]
    },
    {
        path: '/message',
        component: () => import(/* webpackChunkName: "message" */'../views/backendviews/messages/Message'),
        children:[
            {   path: '/message/userlist',
                name: 'userlist',
                meta: {
                    layout: "backend",
                    backRoute: true,
                    title: "Messages"
                },
                component: () => import(/* webpackChunkName: "message" */'../views/backendviews/messages/UserList'),
                beforeEnter: userIsVerify
            }

        ]
    },



    {
        path: '/search',
        name: 'search',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"Search"
        },
        component: () => import(/* webpackChunkName: "search" */'../views/backendviews/search/SearchPage'),
        beforeEnter: userIsVerify
    },
    {
        path: '/filter',
        name: 'filter',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"Filter"
        },
        component: () => import(/* webpackChunkName: "search" */'../views/backendviews/search/FilterPage'),
        beforeEnter: userIsVerify
    },
    {
        path: '/filter-agent',
        name: 'filter_agent',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"Filter"
        },
        component: () => import(/* webpackChunkName: "search" */'../views/backendviews/search/FilterAgentPage'),
        beforeEnter: userIsVerify
    },
    //side menu routes
    {
        path: '/profile',
        name: 'profile',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"My Profile"
        },
        component: () => import(/* webpackChunkName: "profile" */'../views/backendviews/side_menu/UserProfile'),
        beforeEnter: userIsVerify
    },
    {
        path: '/edit-profile',
        name: 'edit_profile',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"Update Profile"
        },
        component: () => import(/* webpackChunkName: "profile" */'../views/backendviews/side_menu/EditProfile'),
        beforeEnter: userIsVerify
    },

    {
        path: '/mylisting',
        name: 'mylisting',
        meta: {
            layout: "backend",
            backRoute: true,
            title: "My Listings"
        },
        component: () => import(/* webpackChunkName: "mylisting" */'../views/backendviews/side_menu/MyListing'),
        beforeEnter: userIsVerify
    },
    {
        path: '/mycollection',
        name: 'mycollection',
        meta: {
            layout: "backend",
            backRoute: true,
            title: "Saved Properties"
        },
        component: () => import(/* webpackChunkName: "mycollection" */'../views/backendviews/side_menu/MyCollection'),
        beforeEnter: userIsVerify
    },
    {
        path: '/property-rquest',
        name: 'property_request',
        meta: {
            layout: "backend",
            backRoute: true,
            title: "Request"
        },
        component: () => import(/* webpackChunkName: "requestform" */'../views/backendviews/fab_menu/RequestForm'),
         beforeEnter: userIsVerify
    },
    {
        path: '/add-property/:type',
        name: 'add_property',
        meta: {
            layout: "backend",
            backRoute: true, 
            title:"New Property"
        },
        component: () => import(/* webpackChunkName: "add-property" */'../views/backendviews/side_menu/AddProperty'),
         beforeEnter: userIsVerify
    },

    {
        path: '/searchresult',
        name: 'searchresult',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"Result"
        },
        component: ()=>import('../views/backendviews/search/SearchResult'),
        beforeEnter: userIsVerify
    },
  {
        path: '/propertydetail/:slug',
        name: 'propertydetail',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"Detail"
        },
      component: () => import(/* webpackChunkName: "details" */'../views/backendviews/PropertyDetail'),
       beforeEnter: userIsVerify
    },
    {
        path: '/property-offer-list/:slug',
        name: 'property_offer_list',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"Property Offer"
        },
        component: () => import(/* webpackChunkName: "details" */'../views/backendviews/bottom_sheet/PropertyOfferList')
    },
    {
        path: '/property-inspection-list/:slug',
        name: 'property_inspection_list',
        meta: {
            layout: "backend",
            backRoute: true,
            title:"Inspection"
        },
        component: () => import(/* webpackChunkName: "details" */'../views/backendviews/bottom_sheet/PropertyInspectionList')
    },
    {
        path: 'offline',
        name: 'offline',
        meta: {
          layout: 'offline',
          title:"No Network"
        },
        component: () => import(/* webpackChunkName: "page404" */'@/views/frontendviews/Offline')
      },
];
export default backend_routes;